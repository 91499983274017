export const configData = 
{
"yelp_activo":true,
"idioma": "pt",
"provincia_o_estado": "Santa Catarina",
"ciudad": "cidade",
"web":"https://www.pekegames.app/pt-BR/Home/App/Santa-Catarina",
"eltiempo":  [
    {
      "@value": "",
      "@selected": "",
      "#text": "Selecciona una ciudad"
    },
    {
      "@value": "56683",
      "#text": "Abadiânia"
    },
    {
      "@value": "56684",
      "#text": "Abaeté"
    },
    {
      "@value": "56685",
      "#text": "Abaetetuba"
    },
    {
      "@value": "56686",
      "#text": "Ábidos"
    },
    {
      "@value": "56687",
      "#text": "Abreu e Lima"
    },
    {
      "@value": "56688",
      "#text": "Acajutiba"
    },
    {
      "@value": "56689",
      "#text": "Acará"
    },
    {
      "@value": "56690",
      "#text": "Acaraú"
    },
    {
      "@value": "56691",
      "#text": "Acopiara"
    },
    {
      "@value": "56692",
      "#text": "Açu"
    },
    {
      "@value": "56693",
      "#text": "Açucena"
    },
    {
      "@value": "56694",
      "#text": "Adamantina"
    },
    {
      "@value": "56695",
      "#text": "Afogados da Ingazeira"
    },
    {
      "@value": "56696",
      "#text": "Afonso Bezerra"
    },
    {
      "@value": "56697",
      "#text": "Afonso Cláudio"
    },
    {
      "@value": "31073",
      "#text": "Afonsos Aeroporto"
    },
    {
      "@value": "56698",
      "#text": "Afuá"
    },
    {
      "@value": "56699",
      "#text": "Agrestina"
    },
    {
      "@value": "56700",
      "#text": "Água Branca"
    },
    {
      "@value": "56701",
      "#text": "Água Preta"
    },
    {
      "@value": "56702",
      "#text": "Aguaí"
    },
    {
      "@value": "56703",
      "#text": "Águas Belas"
    },
    {
      "@value": "56704",
      "#text": "Águas de Lindóia"
    },
    {
      "@value": "56705",
      "#text": "Águas Formosas"
    },
    {
      "@value": "56706",
      "#text": "Águas Vermelhas"
    },
    {
      "@value": "56707",
      "#text": "Agudos"
    },
    {
      "@value": "56708",
      "#text": "Águia Branca"
    },
    {
      "@value": "56709",
      "#text": "Aimorés"
    },
    {
      "@value": "56710",
      "#text": "Alagoa Grande"
    },
    {
      "@value": "56711",
      "#text": "Alagoa Nova"
    },
    {
      "@value": "56712",
      "#text": "Alagoinha"
    },
    {
      "@value": "56713",
      "#text": "Alagoinhas"
    },
    {
      "@value": "33334",
      "#text": "Albacora P25 Oil"
    },
    {
      "@value": "56714",
      "#text": "Alcântara"
    },
    {
      "@value": "56715",
      "#text": "Alegre"
    },
    {
      "@value": "56716",
      "#text": "Alegrete"
    },
    {
      "@value": "56717",
      "#text": "Além Paraíba"
    },
    {
      "@value": "56718",
      "#text": "Alenquer"
    },
    {
      "@value": "56719",
      "#text": "Alexandria"
    },
    {
      "@value": "56720",
      "#text": "Alfenas"
    },
    {
      "@value": "56721",
      "#text": "Alfredo Chaves"
    },
    {
      "@value": "56722",
      "#text": "Alhandra"
    },
    {
      "@value": "56723",
      "#text": "Almeirim"
    },
    {
      "@value": "56724",
      "#text": "Almenara"
    },
    {
      "@value": "56725",
      "#text": "Almirante Tamandaré"
    },
    {
      "@value": "56726",
      "#text": "Alpinópolis"
    },
    {
      "@value": "56727",
      "#text": "Alta Floresta"
    },
    {
      "@value": "31076",
      "#text": "Alta Floresta Aeroporto"
    },
    {
      "@value": "56728",
      "#text": "Altamira"
    },
    {
      "@value": "56729",
      "#text": "Altãnia"
    },
    {
      "@value": "56730",
      "#text": "Alterosa"
    },
    {
      "@value": "56731",
      "#text": "Altinho"
    },
    {
      "@value": "56732",
      "#text": "Altinópolis"
    },
    {
      "@value": "56733",
      "#text": "Alto Araguaia"
    },
    {
      "@value": "56734",
      "#text": "Alto Longá"
    },
    {
      "@value": "56735",
      "#text": "Alto Paraná"
    },
    {
      "@value": "56736",
      "#text": "Alto Piquiri"
    },
    {
      "@value": "56737",
      "#text": "Altos"
    },
    {
      "@value": "56738",
      "#text": "Alvarães"
    },
    {
      "@value": "56739",
      "#text": "Álvares Machado"
    },
    {
      "@value": "56740",
      "#text": "Alvinópolis"
    },
    {
      "@value": "56741",
      "#text": "Alvorada"
    },
    {
      "@value": "33306",
      "#text": "Amapa Airport"
    },
    {
      "@value": "56742",
      "#text": "Amaraji"
    },
    {
      "@value": "56743",
      "#text": "Amarante"
    },
    {
      "@value": "56744",
      "#text": "Amarante do Maranhão"
    },
    {
      "@value": "56745",
      "#text": "Amargosa"
    },
    {
      "@value": "56746",
      "#text": "América Dourada"
    },
    {
      "@value": "56747",
      "#text": "Americana"
    },
    {
      "@value": "56748",
      "#text": "Américo Brasiliense"
    },
    {
      "@value": "56749",
      "#text": "Amontada"
    },
    {
      "@value": "56750",
      "#text": "Amparo"
    },
    {
      "@value": "56751",
      "#text": "Ampére"
    },
    {
      "@value": "56752",
      "#text": "Anadia"
    },
    {
      "@value": "56753",
      "#text": "Anagé"
    },
    {
      "@value": "56754",
      "#text": "Anajatuba"
    },
    {
      "@value": "56755",
      "#text": "Ananindeua"
    },
    {
      "@value": "56756",
      "#text": "Anápolis"
    },
    {
      "@value": "31074",
      "#text": "Anapolis Braz-Afb"
    },
    {
      "@value": "56757",
      "#text": "Anastácio"
    },
    {
      "@value": "56758",
      "#text": "Andradas"
    },
    {
      "@value": "56759",
      "#text": "Andradina"
    },
    {
      "@value": "56760",
      "#text": "Andrelândia"
    },
    {
      "@value": "56761",
      "#text": "Angatuba"
    },
    {
      "@value": "56762",
      "#text": "Angicos"
    },
    {
      "@value": "56763",
      "#text": "Angra dos Reis"
    },
    {
      "@value": "56764",
      "#text": "Anicuns"
    },
    {
      "@value": "56765",
      "#text": "Anitápolis"
    },
    {
      "@value": "56766",
      "#text": "Anori"
    },
    {
      "@value": "56767",
      "#text": "Antonina"
    },
    {
      "@value": "56768",
      "#text": "Aparecida"
    },
    {
      "@value": "56769",
      "#text": "Aparecida de Goiânia"
    },
    {
      "@value": "56770",
      "#text": "Aparecida do Taboado"
    },
    {
      "@value": "56771",
      "#text": "Apiaí"
    },
    {
      "@value": "56772",
      "#text": "Apodi"
    },
    {
      "@value": "56773",
      "#text": "Apucarana"
    },
    {
      "@value": "56774",
      "#text": "Aquidabã"
    },
    {
      "@value": "56775",
      "#text": "Aquidauana"
    },
    {
      "@value": "56776",
      "#text": "Aquiraz"
    },
    {
      "@value": "56777",
      "#text": "Aracaju"
    },
    {
      "@value": "31075",
      "#text": "Aracaju Aeroporto"
    },
    {
      "@value": "56778",
      "#text": "Aracati"
    },
    {
      "@value": "56779",
      "#text": "Araçatuba"
    },
    {
      "@value": "56780",
      "#text": "Araci"
    },
    {
      "@value": "56781",
      "#text": "Aracoiaba"
    },
    {
      "@value": "56782",
      "#text": "Araçoiaba da Serra"
    },
    {
      "@value": "56783",
      "#text": "Aracruz"
    },
    {
      "@value": "56784",
      "#text": "Araçuaí"
    },
    {
      "@value": "56785",
      "#text": "Aragarças"
    },
    {
      "@value": "56786",
      "#text": "Araguaiana"
    },
    {
      "@value": "56787",
      "#text": "Araguaína"
    },
    {
      "@value": "56788",
      "#text": "Araguari"
    },
    {
      "@value": "56789",
      "#text": "Araioses"
    },
    {
      "@value": "56790",
      "#text": "Arapiraca"
    },
    {
      "@value": "56791",
      "#text": "Arapongas"
    },
    {
      "@value": "56792",
      "#text": "Arara"
    },
    {
      "@value": "56793",
      "#text": "Araranguá"
    },
    {
      "@value": "56794",
      "#text": "Araraquara"
    },
    {
      "@value": "56795",
      "#text": "Araras"
    },
    {
      "@value": "56796",
      "#text": "Arari"
    },
    {
      "@value": "56797",
      "#text": "Araripe"
    },
    {
      "@value": "56798",
      "#text": "Araripina"
    },
    {
      "@value": "56799",
      "#text": "Araruama"
    },
    {
      "@value": "56800",
      "#text": "Araruna"
    },
    {
      "@value": "56801",
      "#text": "Arauá"
    },
    {
      "@value": "56802",
      "#text": "Araucária"
    },
    {
      "@value": "56803",
      "#text": "Araxá"
    },
    {
      "@value": "56804",
      "#text": "Arcos"
    },
    {
      "@value": "56805",
      "#text": "Arcoverde"
    },
    {
      "@value": "56806",
      "#text": "Areado"
    },
    {
      "@value": "56807",
      "#text": "Areal"
    },
    {
      "@value": "56808",
      "#text": "Areia"
    },
    {
      "@value": "56809",
      "#text": "Areia Branca"
    },
    {
      "@value": "56810",
      "#text": "Arenápolis"
    },
    {
      "@value": "56811",
      "#text": "Arês"
    },
    {
      "@value": "56812",
      "#text": "Arinos"
    },
    {
      "@value": "56813",
      "#text": "Aripuanã"
    },
    {
      "@value": "56814",
      "#text": "Ariquemes"
    },
    {
      "@value": "56815",
      "#text": "Armação"
    },
    {
      "@value": "56816",
      "#text": "Armação dos Búzios"
    },
    {
      "@value": "56817",
      "#text": "Aroeiras"
    },
    {
      "@value": "56818",
      "#text": "Arraial do Cabo"
    },
    {
      "@value": "56819",
      "#text": "Arroio do Meio"
    },
    {
      "@value": "56820",
      "#text": "Arroio dos Ratos"
    },
    {
      "@value": "56821",
      "#text": "Arroio Grande"
    },
    {
      "@value": "56822",
      "#text": "Artur Nogueira"
    },
    {
      "@value": "56823",
      "#text": "Arujá"
    },
    {
      "@value": "56824",
      "#text": "Assaí"
    },
    {
      "@value": "56825",
      "#text": "Assaré"
    },
    {
      "@value": "56826",
      "#text": "Assis"
    },
    {
      "@value": "56827",
      "#text": "Astolfo Dutra"
    },
    {
      "@value": "56828",
      "#text": "Astorga"
    },
    {
      "@value": "56829",
      "#text": "Atalaia"
    },
    {
      "@value": "56830",
      "#text": "Atibaia"
    },
    {
      "@value": "56831",
      "#text": "Augusto Corrêa"
    },
    {
      "@value": "56832",
      "#text": "Auriflama"
    },
    {
      "@value": "56833",
      "#text": "Aurora"
    },
    {
      "@value": "56834",
      "#text": "Autazes"
    },
    {
      "@value": "56835",
      "#text": "Avaré"
    },
    {
      "@value": "56836",
      "#text": "Bacabal"
    },
    {
      "@value": "56837",
      "#text": "Bady Bassitt"
    },
    {
      "@value": "56838",
      "#text": "Baependi"
    },
    {
      "@value": "56839",
      "#text": "Bagé"
    },
    {
      "@value": "31079",
      "#text": "Bage Aeroporto"
    },
    {
      "@value": "56840",
      "#text": "Baião"
    },
    {
      "@value": "56841",
      "#text": "Baixa Grande"
    },
    {
      "@value": "56842",
      "#text": "Baixo Guandu"
    },
    {
      "@value": "56843",
      "#text": "Balneário Camboriú"
    },
    {
      "@value": "56844",
      "#text": "Balsas"
    },
    {
      "@value": "56845",
      "#text": "Bambuí"
    },
    {
      "@value": "56846",
      "#text": "Bananeiras"
    },
    {
      "@value": "56847",
      "#text": "Bandeirantes"
    },
    {
      "@value": "56848",
      "#text": "Barão de Cocais"
    },
    {
      "@value": "56849",
      "#text": "Barão de Melgaço"
    },
    {
      "@value": "56850",
      "#text": "Baraúna"
    },
    {
      "@value": "56851",
      "#text": "Barbacena"
    },
    {
      "@value": "56852",
      "#text": "Barbalha"
    },
    {
      "@value": "56853",
      "#text": "Barcarena"
    },
    {
      "@value": "31373",
      "#text": "Barcelos"
    },
    {
      "@value": "56854",
      "#text": "Barcelos (Amazonas)"
    },
    {
      "@value": "56855",
      "#text": "Bariri"
    },
    {
      "@value": "56856",
      "#text": "Barra"
    },
    {
      "@value": "56857",
      "#text": "Barra Bonita"
    },
    {
      "@value": "56858",
      "#text": "Barra da Estiva"
    },
    {
      "@value": "56859",
      "#text": "Barra de Santo Antônio"
    },
    {
      "@value": "56860",
      "#text": "Barra de São Francisco"
    },
    {
      "@value": "56861",
      "#text": "Barra do Bugres"
    },
    {
      "@value": "56862",
      "#text": "Barra do Corda"
    },
    {
      "@value": "56863",
      "#text": "Barra do Garças"
    },
    {
      "@value": "56864",
      "#text": "Barra do Piraí"
    },
    {
      "@value": "56865",
      "#text": "Barra dos Coqueiros"
    },
    {
      "@value": "56866",
      "#text": "Barra Mansa"
    },
    {
      "@value": "56867",
      "#text": "Barra Velha"
    },
    {
      "@value": "56868",
      "#text": "Barras"
    },
    {
      "@value": "56869",
      "#text": "Barreiras"
    },
    {
      "@value": "56870",
      "#text": "Barreirinha"
    },
    {
      "@value": "56871",
      "#text": "Barreirinhas"
    },
    {
      "@value": "56872",
      "#text": "Barreiro do Jaíba"
    },
    {
      "@value": "56873",
      "#text": "Barreiros"
    },
    {
      "@value": "56874",
      "#text": "Barretos"
    },
    {
      "@value": "56875",
      "#text": "Barrinha"
    },
    {
      "@value": "56876",
      "#text": "Barro"
    },
    {
      "@value": "56877",
      "#text": "Barro Alto"
    },
    {
      "@value": "56878",
      "#text": "Barroquinha"
    },
    {
      "@value": "56879",
      "#text": "Barroso"
    },
    {
      "@value": "56880",
      "#text": "Barueri"
    },
    {
      "@value": "56881",
      "#text": "Bastos"
    },
    {
      "@value": "56882",
      "#text": "Bataiporã"
    },
    {
      "@value": "56883",
      "#text": "Batalha"
    },
    {
      "@value": "56884",
      "#text": "Batatais"
    },
    {
      "@value": "56885",
      "#text": "Baturité"
    },
    {
      "@value": "56886",
      "#text": "Bauru"
    },
    {
      "@value": "56887",
      "#text": "Bayeux"
    },
    {
      "@value": "56888",
      "#text": "Bebedouro"
    },
    {
      "@value": "56889",
      "#text": "Beberibe"
    },
    {
      "@value": "56890",
      "#text": "Bela Cruz"
    },
    {
      "@value": "56891",
      "#text": "Bela Vista"
    },
    {
      "@value": "56892",
      "#text": "Bela Vista de Goiás"
    },
    {
      "@value": "56893",
      "#text": "Bela Vista do Paraíso"
    },
    {
      "@value": "56894",
      "#text": "Belém"
    },
    {
      "@value": "31078",
      "#text": "Belem Aeroporto"
    },
    {
      "@value": "56895",
      "#text": "Belém de São Francisco"
    },
    {
      "@value": "56896",
      "#text": "Belém do Brejo do Cruz"
    },
    {
      "@value": "56897",
      "#text": "Belford Roxo"
    },
    {
      "@value": "56898",
      "#text": "Belmonte"
    },
    {
      "@value": "56899",
      "#text": "Belo Horizonte"
    },
    {
      "@value": "31080",
      "#text": "Belo Horizonte Aeroporto"
    },
    {
      "@value": "56900",
      "#text": "Belo Jardim"
    },
    {
      "@value": "56901",
      "#text": "Belo Oriente"
    },
    {
      "@value": "56902",
      "#text": "Beneditinos"
    },
    {
      "@value": "56903",
      "#text": "Benevides"
    },
    {
      "@value": "56904",
      "#text": "Benjamin Constant"
    },
    {
      "@value": "56905",
      "#text": "Bento Gonçalves"
    },
    {
      "@value": "56906",
      "#text": "Bequimão"
    },
    {
      "@value": "56907",
      "#text": "Bernardino de Campos"
    },
    {
      "@value": "56908",
      "#text": "Bertioga"
    },
    {
      "@value": "56909",
      "#text": "Betim"
    },
    {
      "@value": "56910",
      "#text": "Bezerros"
    },
    {
      "@value": "56911",
      "#text": "Bicas"
    },
    {
      "@value": "56912",
      "#text": "Biguaçu"
    },
    {
      "@value": "56913",
      "#text": "Birigui"
    },
    {
      "@value": "56914",
      "#text": "Biritiba Mirim"
    },
    {
      "@value": "56915",
      "#text": "Blumenau"
    },
    {
      "@value": "56916",
      "#text": "Boa Esperança"
    },
    {
      "@value": "56917",
      "#text": "Boa Esperança do Sul"
    },
    {
      "@value": "56918",
      "#text": "Boa Viagem"
    },
    {
      "@value": "56919",
      "#text": "Boa Vista"
    },
    {
      "@value": "31085",
      "#text": "Boa Vista Aeropor-To"
    },
    {
      "@value": "56920",
      "#text": "Boca do Acre"
    },
    {
      "@value": "33311",
      "#text": "Bocadoacre"
    },
    {
      "@value": "56921",
      "#text": "Bocaiúva"
    },
    {
      "@value": "56922",
      "#text": "Boituva"
    },
    {
      "@value": "56923",
      "#text": "Bom Conselho"
    },
    {
      "@value": "56924",
      "#text": "Bom Despacho"
    },
    {
      "@value": "56925",
      "#text": "Bom Jardim"
    },
    {
      "@value": "56926",
      "#text": "Bom Jesus"
    },
    {
      "@value": "56927",
      "#text": "Bom Jesus da Lapa"
    },
    {
      "@value": "56928",
      "#text": "Bom Jesus do Galho"
    },
    {
      "@value": "56929",
      "#text": "Bom Jesus do Itabapoana"
    },
    {
      "@value": "56930",
      "#text": "Bom Jesus dos Perdões"
    },
    {
      "@value": "56931",
      "#text": "Bom Sucesso"
    },
    {
      "@value": "56932",
      "#text": "Bonito"
    },
    {
      "@value": "56933",
      "#text": "Boqueirão (1)"
    },
    {
      "@value": "56934",
      "#text": "Boquim"
    },
    {
      "@value": "56935",
      "#text": "Boquira"
    },
    {
      "@value": "56936",
      "#text": "Borba"
    },
    {
      "@value": "56937",
      "#text": "Borborema"
    },
    {
      "@value": "56938",
      "#text": "Borda da Mata"
    },
    {
      "@value": "56939",
      "#text": "Botelhos"
    },
    {
      "@value": "56940",
      "#text": "Botucatu"
    },
    {
      "@value": "56941",
      "#text": "Braço do Norte"
    },
    {
      "@value": "56942",
      "#text": "Bragança"
    },
    {
      "@value": "56943",
      "#text": "Bragança Paulista"
    },
    {
      "@value": "56944",
      "#text": "Brasiléia"
    },
    {
      "@value": "56945",
      "#text": "Brasília"
    },
    {
      "@value": "31083",
      "#text": "Brasilia Aeroporto"
    },
    {
      "@value": "56946",
      "#text": "Brasília de Minas"
    },
    {
      "@value": "56947",
      "#text": "Brejinho"
    },
    {
      "@value": "56948",
      "#text": "Brejo"
    },
    {
      "@value": "56949",
      "#text": "Brejo da Madre de Deus"
    },
    {
      "@value": "56950",
      "#text": "Brejo Santo"
    },
    {
      "@value": "56951",
      "#text": "Breves"
    },
    {
      "@value": "56952",
      "#text": "Brodósqui"
    },
    {
      "@value": "56953",
      "#text": "Brotas"
    },
    {
      "@value": "56954",
      "#text": "Brumadinho"
    },
    {
      "@value": "56955",
      "#text": "Brumado"
    },
    {
      "@value": "56956",
      "#text": "Brusque"
    },
    {
      "@value": "56957",
      "#text": "Bueno Brandão"
    },
    {
      "@value": "56958",
      "#text": "Buerarema"
    },
    {
      "@value": "56959",
      "#text": "Buíque"
    },
    {
      "@value": "56960",
      "#text": "Bujaru"
    },
    {
      "@value": "56961",
      "#text": "Buri"
    },
    {
      "@value": "56962",
      "#text": "Buritama"
    },
    {
      "@value": "56963",
      "#text": "Buriti Alegre"
    },
    {
      "@value": "56964",
      "#text": "Buriti Bravo"
    },
    {
      "@value": "56965",
      "#text": "Buriti dos Lopes"
    },
    {
      "@value": "56966",
      "#text": "Buritis"
    },
    {
      "@value": "56967",
      "#text": "Buritizeiro"
    },
    {
      "@value": "56968",
      "#text": "Butiá"
    },
    {
      "@value": "33313",
      "#text": "Buzios/Modiano"
    },
    {
      "@value": "56969",
      "#text": "Caaporã"
    },
    {
      "@value": "56970",
      "#text": "Caarapó"
    },
    {
      "@value": "56971",
      "#text": "Cabedelo"
    },
    {
      "@value": "56972",
      "#text": "Cabo"
    },
    {
      "@value": "56973",
      "#text": "Cabo Frio"
    },
    {
      "@value": "56974",
      "#text": "Cabreúva"
    },
    {
      "@value": "56975",
      "#text": "Cabrobó"
    },
    {
      "@value": "56976",
      "#text": "Caçador"
    },
    {
      "@value": "56977",
      "#text": "Caçapava"
    },
    {
      "@value": "56978",
      "#text": "Caçapava do Sul"
    },
    {
      "@value": "56979",
      "#text": "Cacequi"
    },
    {
      "@value": "31087",
      "#text": "Cachimbo"
    },
    {
      "@value": "33363",
      "#text": "Cachimbo-In-Par"
    },
    {
      "@value": "56980",
      "#text": "Cachoeira"
    },
    {
      "@value": "56981",
      "#text": "Cachoeira do Sul"
    },
    {
      "@value": "56982",
      "#text": "Cachoeiras de Macacu"
    },
    {
      "@value": "56983",
      "#text": "Cachoeirinha"
    },
    {
      "@value": "56984",
      "#text": "Cachoeiro de Itapemirim"
    },
    {
      "@value": "56985",
      "#text": "Cacimba de Dentro"
    },
    {
      "@value": "56986",
      "#text": "Cacimbinhas"
    },
    {
      "@value": "56987",
      "#text": "Cacoal"
    },
    {
      "@value": "56988",
      "#text": "Caconde"
    },
    {
      "@value": "56989",
      "#text": "Caçu"
    },
    {
      "@value": "56990",
      "#text": "Caculé"
    },
    {
      "@value": "56991",
      "#text": "Caeté"
    },
    {
      "@value": "56992",
      "#text": "Caetés"
    },
    {
      "@value": "56993",
      "#text": "Caetité"
    },
    {
      "@value": "56994",
      "#text": "Cafarnaum"
    },
    {
      "@value": "56995",
      "#text": "Cafelândia"
    },
    {
      "@value": "56996",
      "#text": "Caiapônia"
    },
    {
      "@value": "56997",
      "#text": "Caicó"
    },
    {
      "@value": "56998",
      "#text": "Caieiras"
    },
    {
      "@value": "56999",
      "#text": "Cajamar"
    },
    {
      "@value": "57000",
      "#text": "Cajati"
    },
    {
      "@value": "57001",
      "#text": "Cajazeiras"
    },
    {
      "@value": "57002",
      "#text": "Cajueiro"
    },
    {
      "@value": "57003",
      "#text": "Cajuru"
    },
    {
      "@value": "57004",
      "#text": "Caldas Novas"
    },
    {
      "@value": "57005",
      "#text": "Camaçari"
    },
    {
      "@value": "57006",
      "#text": "Camamu"
    },
    {
      "@value": "57007",
      "#text": "Camanducaia"
    },
    {
      "@value": "57008",
      "#text": "Camapuã"
    },
    {
      "@value": "57009",
      "#text": "Camaquã"
    },
    {
      "@value": "57010",
      "#text": "Cambará"
    },
    {
      "@value": "57011",
      "#text": "Cambé"
    },
    {
      "@value": "57012",
      "#text": "Cambebba"
    },
    {
      "@value": "57013",
      "#text": "Cambuci"
    },
    {
      "@value": "57014",
      "#text": "Cambuí"
    },
    {
      "@value": "57015",
      "#text": "Cambuquira"
    },
    {
      "@value": "57016",
      "#text": "Cametá"
    },
    {
      "@value": "57017",
      "#text": "Camocim"
    },
    {
      "@value": "57018",
      "#text": "Camocim de São Félix"
    },
    {
      "@value": "57019",
      "#text": "Campanha"
    },
    {
      "@value": "57020",
      "#text": "Campestre"
    },
    {
      "@value": "57021",
      "#text": "Campina Grande"
    },
    {
      "@value": "57022",
      "#text": "Campina Grande do Sul"
    },
    {
      "@value": "57023",
      "#text": "Campina Verde"
    },
    {
      "@value": "57024",
      "#text": "Campinas"
    },
    {
      "@value": "31120",
      "#text": "Campinas Aeroporto"
    },
    {
      "@value": "57025",
      "#text": "Campo Alegre"
    },
    {
      "@value": "57026",
      "#text": "Campo Belo"
    },
    {
      "@value": "57027",
      "#text": "Campo do Brito"
    },
    {
      "@value": "57028",
      "#text": "Campo Formoso"
    },
    {
      "@value": "57029",
      "#text": "Campo Grande"
    },
    {
      "@value": "31089",
      "#text": "Campo Grande Aeroporto"
    },
    {
      "@value": "57030",
      "#text": "Campo Largo"
    },
    {
      "@value": "57031",
      "#text": "Campo Maior"
    },
    {
      "@value": "57032",
      "#text": "Campo Mourão"
    },
    {
      "@value": "57033",
      "#text": "Campo Verde"
    },
    {
      "@value": "57034",
      "#text": "Campos"
    },
    {
      "@value": "57035",
      "#text": "Campos Altos"
    },
    {
      "@value": "57036",
      "#text": "Campos Belos"
    },
    {
      "@value": "57037",
      "#text": "Campos do Jordão"
    },
    {
      "@value": "57038",
      "#text": "Campos Gerais"
    },
    {
      "@value": "57039",
      "#text": "Campos Novos"
    },
    {
      "@value": "57040",
      "#text": "Campos Sales"
    },
    {
      "@value": "57041",
      "#text": "Cananéia"
    },
    {
      "@value": "57042",
      "#text": "Canarana"
    },
    {
      "@value": "57043",
      "#text": "Canavieiras"
    },
    {
      "@value": "57044",
      "#text": "Candelária"
    },
    {
      "@value": "57045",
      "#text": "Cândido de Abreu"
    },
    {
      "@value": "57046",
      "#text": "Cândido Mendes"
    },
    {
      "@value": "57047",
      "#text": "Cândido Mota"
    },
    {
      "@value": "57048",
      "#text": "Canela"
    },
    {
      "@value": "57049",
      "#text": "Canguaretama"
    },
    {
      "@value": "57050",
      "#text": "Canguçu"
    },
    {
      "@value": "57051",
      "#text": "Canhotinho"
    },
    {
      "@value": "57052",
      "#text": "Canindé"
    },
    {
      "@value": "57053",
      "#text": "Canindé de São Francisco"
    },
    {
      "@value": "57054",
      "#text": "Canoas"
    },
    {
      "@value": "57055",
      "#text": "Canoinhas"
    },
    {
      "@value": "57056",
      "#text": "Cantagalo"
    },
    {
      "@value": "57057",
      "#text": "Cantanhede"
    },
    {
      "@value": "57058",
      "#text": "Canto do Buriti"
    },
    {
      "@value": "57059",
      "#text": "Canutama"
    },
    {
      "@value": "57060",
      "#text": "Capanema"
    },
    {
      "@value": "57061",
      "#text": "Capâo Bonito"
    },
    {
      "@value": "57062",
      "#text": "Capão da Canoa"
    },
    {
      "@value": "57063",
      "#text": "Capela"
    },
    {
      "@value": "57064",
      "#text": "Capela do Alto"
    },
    {
      "@value": "57065",
      "#text": "Capelinha"
    },
    {
      "@value": "57066",
      "#text": "Capim Grosso"
    },
    {
      "@value": "57067",
      "#text": "Capinópolis"
    },
    {
      "@value": "57068",
      "#text": "Capinzal"
    },
    {
      "@value": "57069",
      "#text": "Capitão Poço"
    },
    {
      "@value": "57070",
      "#text": "Capivari"
    },
    {
      "@value": "57071",
      "#text": "Capoeiras"
    },
    {
      "@value": "57072",
      "#text": "Caraguatatuba"
    },
    {
      "@value": "57073",
      "#text": "Caraí"
    },
    {
      "@value": "31091",
      "#text": "Carajas / Maraba"
    },
    {
      "@value": "57074",
      "#text": "Carambeí"
    },
    {
      "@value": "57075",
      "#text": "Carandaí"
    },
    {
      "@value": "57076",
      "#text": "Carangola"
    },
    {
      "@value": "57077",
      "#text": "Carapicuíba"
    },
    {
      "@value": "57078",
      "#text": "Caratinga"
    },
    {
      "@value": "57079",
      "#text": "Carauari"
    },
    {
      "@value": "57080",
      "#text": "Caraúbas"
    },
    {
      "@value": "31096",
      "#text": "Caravelas Aeropor-To"
    },
    {
      "@value": "57081",
      "#text": "Carazinho"
    },
    {
      "@value": "57082",
      "#text": "Cardoso"
    },
    {
      "@value": "57083",
      "#text": "Careiro da Várzea"
    },
    {
      "@value": "57084",
      "#text": "Carianos"
    },
    {
      "@value": "57085",
      "#text": "Carinhanha"
    },
    {
      "@value": "57086",
      "#text": "Carira"
    },
    {
      "@value": "57087",
      "#text": "Cariré"
    },
    {
      "@value": "57088",
      "#text": "Caririaçu"
    },
    {
      "@value": "57089",
      "#text": "Carlos Barbosa"
    },
    {
      "@value": "57090",
      "#text": "Carlos Chagas"
    },
    {
      "@value": "57091",
      "#text": "Carmo"
    },
    {
      "@value": "57092",
      "#text": "Carmo do Cajuru"
    },
    {
      "@value": "57093",
      "#text": "Carmo do Paranaíba"
    },
    {
      "@value": "57094",
      "#text": "Carmo do Rio Claro"
    },
    {
      "@value": "57095",
      "#text": "Carmo do Rio Verde"
    },
    {
      "@value": "57096",
      "#text": "Carnaíba"
    },
    {
      "@value": "57097",
      "#text": "Carolina"
    },
    {
      "@value": "57098",
      "#text": "Carpina"
    },
    {
      "@value": "57099",
      "#text": "Caruaru"
    },
    {
      "@value": "57100",
      "#text": "Carutapera"
    },
    {
      "@value": "57101",
      "#text": "Carvoeira"
    },
    {
      "@value": "57102",
      "#text": "Casa Branca"
    },
    {
      "@value": "57103",
      "#text": "Cascavel"
    },
    {
      "@value": "57104",
      "#text": "Casimiro de Abreu"
    },
    {
      "@value": "57105",
      "#text": "Cássia"
    },
    {
      "@value": "57106",
      "#text": "Cassilândia"
    },
    {
      "@value": "57107",
      "#text": "Castanhal"
    },
    {
      "@value": "57108",
      "#text": "Castelo"
    },
    {
      "@value": "57109",
      "#text": "Castelo do Piauí"
    },
    {
      "@value": "57110",
      "#text": "Castilho"
    },
    {
      "@value": "57111",
      "#text": "Castro"
    },
    {
      "@value": "57112",
      "#text": "Castro Alves"
    },
    {
      "@value": "57113",
      "#text": "Cataguases"
    },
    {
      "@value": "57114",
      "#text": "Catalão"
    },
    {
      "@value": "57115",
      "#text": "Catanduva"
    },
    {
      "@value": "57116",
      "#text": "Catende"
    },
    {
      "@value": "57117",
      "#text": "Catolé do Rocha"
    },
    {
      "@value": "57118",
      "#text": "Catu"
    },
    {
      "@value": "57119",
      "#text": "Caucaia"
    },
    {
      "@value": "57120",
      "#text": "Cavalcante"
    },
    {
      "@value": "57121",
      "#text": "Caxambu"
    },
    {
      "@value": "57122",
      "#text": "Caxias do Sul"
    },
    {
      "@value": "57123",
      "#text": "Ceará Mirim"
    },
    {
      "@value": "57124",
      "#text": "Cedro"
    },
    {
      "@value": "57125",
      "#text": "Celso Ramos"
    },
    {
      "@value": "57126",
      "#text": "Centenário do Sul"
    },
    {
      "@value": "57127",
      "#text": "Centralina"
    },
    {
      "@value": "57128",
      "#text": "Ceres"
    },
    {
      "@value": "57129",
      "#text": "Cerqueira César"
    },
    {
      "@value": "57130",
      "#text": "Cerquilho"
    },
    {
      "@value": "57131",
      "#text": "Cerro Corá"
    },
    {
      "@value": "57132",
      "#text": "Cerro Largo"
    },
    {
      "@value": "57133",
      "#text": "Chã Grande"
    },
    {
      "@value": "33312",
      "#text": "Chafei Amsei"
    },
    {
      "@value": "57134",
      "#text": "Chapada dos Guimarães"
    },
    {
      "@value": "57135",
      "#text": "Chapadinha"
    },
    {
      "@value": "57136",
      "#text": "Chapecó"
    },
    {
      "@value": "57137",
      "#text": "Charqueada"
    },
    {
      "@value": "57138",
      "#text": "Charqueadas"
    },
    {
      "@value": "57139",
      "#text": "Chavantes"
    },
    {
      "@value": "57140",
      "#text": "Chopinzinho"
    },
    {
      "@value": "57141",
      "#text": "Chorozinho"
    },
    {
      "@value": "57142",
      "#text": "Cianorte"
    },
    {
      "@value": "57143",
      "#text": "Cícero Dantas"
    },
    {
      "@value": "57144",
      "#text": "Cidreira"
    },
    {
      "@value": "57145",
      "#text": "Cipó"
    },
    {
      "@value": "57146",
      "#text": "Cláudio"
    },
    {
      "@value": "57147",
      "#text": "Clevelândia"
    },
    {
      "@value": "33322",
      "#text": "Cmp D Goitacazes"
    },
    {
      "@value": "57148",
      "#text": "Coaraci"
    },
    {
      "@value": "57149",
      "#text": "Coari"
    },
    {
      "@value": "57150",
      "#text": "Cocal"
    },
    {
      "@value": "57151",
      "#text": "Codajás"
    },
    {
      "@value": "57152",
      "#text": "Codó"
    },
    {
      "@value": "57153",
      "#text": "Coelho Neto"
    },
    {
      "@value": "57154",
      "#text": "Coité do Nóia"
    },
    {
      "@value": "57155",
      "#text": "Colatina"
    },
    {
      "@value": "57156",
      "#text": "Colinas"
    },
    {
      "@value": "57157",
      "#text": "Colombo"
    },
    {
      "@value": "57158",
      "#text": "Colônia Leopoldina"
    },
    {
      "@value": "57159",
      "#text": "Colorado"
    },
    {
      "@value": "57160",
      "#text": "Conceição da Barra"
    },
    {
      "@value": "57161",
      "#text": "Conceição da Feira"
    },
    {
      "@value": "57162",
      "#text": "Conceição das Alagoas"
    },
    {
      "@value": "57163",
      "#text": "Conceição do Almeida"
    },
    {
      "@value": "57164",
      "#text": "Conceição do Araguaia"
    },
    {
      "@value": "57165",
      "#text": "Conceição do Castelo"
    },
    {
      "@value": "57166",
      "#text": "Conceição do Coité"
    },
    {
      "@value": "57167",
      "#text": "Conceição do Jacuípe"
    },
    {
      "@value": "57168",
      "#text": "Conceição do Mato Dentro"
    },
    {
      "@value": "57169",
      "#text": "Conceição do Rio Verde"
    },
    {
      "@value": "57170",
      "#text": "Conchal"
    },
    {
      "@value": "57171",
      "#text": "Conchas"
    },
    {
      "@value": "57172",
      "#text": "Concórdia"
    },
    {
      "@value": "57173",
      "#text": "Condado"
    },
    {
      "@value": "57174",
      "#text": "Conde"
    },
    {
      "@value": "57175",
      "#text": "Congonhas"
    },
    {
      "@value": "57176",
      "#text": "Conselheiro Lafaiete"
    },
    {
      "@value": "57177",
      "#text": "Conselheiro Pena"
    },
    {
      "@value": "57178",
      "#text": "Contagem"
    },
    {
      "@value": "57179",
      "#text": "Coração de Jesus"
    },
    {
      "@value": "57180",
      "#text": "Coração de Maria"
    },
    {
      "@value": "57181",
      "#text": "Corbélia"
    },
    {
      "@value": "57182",
      "#text": "Cordeiro"
    },
    {
      "@value": "57183",
      "#text": "Cordeirópolis"
    },
    {
      "@value": "57184",
      "#text": "Coreaú"
    },
    {
      "@value": "57185",
      "#text": "Coremas"
    },
    {
      "@value": "57186",
      "#text": "Corinto"
    },
    {
      "@value": "57187",
      "#text": "Coroatá"
    },
    {
      "@value": "57188",
      "#text": "Coromandel"
    },
    {
      "@value": "57189",
      "#text": "Coronel Fabriciano"
    },
    {
      "@value": "57190",
      "#text": "Coronel Vivida"
    },
    {
      "@value": "57191",
      "#text": "Corrego Grande"
    },
    {
      "@value": "57192",
      "#text": "Correia Pinto"
    },
    {
      "@value": "57193",
      "#text": "Correntina"
    },
    {
      "@value": "57194",
      "#text": "Corumbá"
    },
    {
      "@value": "57195",
      "#text": "Corupá"
    },
    {
      "@value": "57196",
      "#text": "Coruripe"
    },
    {
      "@value": "57197",
      "#text": "Cosmópolis"
    },
    {
      "@value": "57198",
      "#text": "Costa Rica"
    },
    {
      "@value": "57199",
      "#text": "Costeira do Pirajubae"
    },
    {
      "@value": "57200",
      "#text": "Cotia"
    },
    {
      "@value": "57201",
      "#text": "Coxim"
    },
    {
      "@value": "57202",
      "#text": "Crateús"
    },
    {
      "@value": "57203",
      "#text": "Crato"
    },
    {
      "@value": "57204",
      "#text": "Cravinhos"
    },
    {
      "@value": "57205",
      "#text": "Criciúma"
    },
    {
      "@value": "57206",
      "#text": "Cristalina"
    },
    {
      "@value": "57207",
      "#text": "Cristinápolis"
    },
    {
      "@value": "57208",
      "#text": "Crixás"
    },
    {
      "@value": "57209",
      "#text": "Cruz"
    },
    {
      "@value": "57210",
      "#text": "Cruz Alta"
    },
    {
      "@value": "57211",
      "#text": "Cruz das Almas"
    },
    {
      "@value": "57212",
      "#text": "Cruz do Espírito Santo"
    },
    {
      "@value": "57213",
      "#text": "Cruzeiro"
    },
    {
      "@value": "57214",
      "#text": "Cruzeiro do Oeste"
    },
    {
      "@value": "31098",
      "#text": "Cruzeiro Do Sul"
    },
    {
      "@value": "57215",
      "#text": "Cruzília"
    },
    {
      "@value": "57216",
      "#text": "Cubatão"
    },
    {
      "@value": "57217",
      "#text": "Cuiabá"
    },
    {
      "@value": "31097",
      "#text": "Cuiaba Aeroporto"
    },
    {
      "@value": "57218",
      "#text": "Cuité"
    },
    {
      "@value": "57219",
      "#text": "Cumaru"
    },
    {
      "@value": "57220",
      "#text": "Cunha"
    },
    {
      "@value": "57221",
      "#text": "Cupira"
    },
    {
      "@value": "57222",
      "#text": "Curaçá"
    },
    {
      "@value": "57223",
      "#text": "Curitiba"
    },
    {
      "@value": "31095",
      "#text": "Curitiba Aeroporto"
    },
    {
      "@value": "57224",
      "#text": "Curitibanos"
    },
    {
      "@value": "57225",
      "#text": "Currais Novos"
    },
    {
      "@value": "57226",
      "#text": "Curuçá"
    },
    {
      "@value": "57227",
      "#text": "Cururupu"
    },
    {
      "@value": "57228",
      "#text": "Curvelo"
    },
    {
      "@value": "57229",
      "#text": "Custódia"
    },
    {
      "@value": "57230",
      "#text": "Delmiro Gouveia"
    },
    {
      "@value": "57231",
      "#text": "Demerval Lobão"
    },
    {
      "@value": "57232",
      "#text": "Descalvado"
    },
    {
      "@value": "57233",
      "#text": "Desterro"
    },
    {
      "@value": "57234",
      "#text": "Diadema"
    },
    {
      "@value": "57235",
      "#text": "Diamantina"
    },
    {
      "@value": "57236",
      "#text": "Diamantino"
    },
    {
      "@value": "57237",
      "#text": "Disney"
    },
    {
      "@value": "57238",
      "#text": "Divino das Laranjeiras"
    },
    {
      "@value": "57239",
      "#text": "Divinópolis"
    },
    {
      "@value": "57240",
      "#text": "Dois Córregos"
    },
    {
      "@value": "57241",
      "#text": "Dois Riachos"
    },
    {
      "@value": "57242",
      "#text": "Dois Vizinhos"
    },
    {
      "@value": "57243",
      "#text": "Dom Pedrito"
    },
    {
      "@value": "57244",
      "#text": "Dom Pedro"
    },
    {
      "@value": "57245",
      "#text": "Domingos Martins"
    },
    {
      "@value": "57246",
      "#text": "Dona Inês"
    },
    {
      "@value": "57247",
      "#text": "Dores do Indaiá"
    },
    {
      "@value": "57248",
      "#text": "Dourados"
    },
    {
      "@value": "57249",
      "#text": "Duartina"
    },
    {
      "@value": "57250",
      "#text": "Duque de Caxias"
    },
    {
      "@value": "57251",
      "#text": "Ecoporanga"
    },
    {
      "@value": "57252",
      "#text": "Edéia"
    },
    {
      "@value": "31100",
      "#text": "Eduardo Gomes International"
    },
    {
      "@value": "57253",
      "#text": "Eirunepé"
    },
    {
      "@value": "57254",
      "#text": "Eldorado"
    },
    {
      "@value": "57255",
      "#text": "Elesbão Veloso"
    },
    {
      "@value": "57256",
      "#text": "Elias Fausto"
    },
    {
      "@value": "57257",
      "#text": "Elói Mendes"
    },
    {
      "@value": "57258",
      "#text": "Embu"
    },
    {
      "@value": "57259",
      "#text": "Embu Guaçu"
    },
    {
      "@value": "57260",
      "#text": "Encantado"
    },
    {
      "@value": "57261",
      "#text": "Encruzilhada"
    },
    {
      "@value": "57262",
      "#text": "Encruzilhada do Sul"
    },
    {
      "@value": "57263",
      "#text": "Engenheiro Beltrão"
    },
    {
      "@value": "57264",
      "#text": "Entre Rios"
    },
    {
      "@value": "57265",
      "#text": "Envira"
    },
    {
      "@value": "57266",
      "#text": "Erechim"
    },
    {
      "@value": "57267",
      "#text": "Escada"
    },
    {
      "@value": "57268",
      "#text": "Esmeraldas"
    },
    {
      "@value": "57269",
      "#text": "Espera Feliz"
    },
    {
      "@value": "57270",
      "#text": "Esperança"
    },
    {
      "@value": "57271",
      "#text": "Esperantina"
    },
    {
      "@value": "57272",
      "#text": "Esperantinópolis"
    },
    {
      "@value": "57273",
      "#text": "Espinosa"
    },
    {
      "@value": "57274",
      "#text": "Espírito Santo do Pinhal"
    },
    {
      "@value": "57275",
      "#text": "Esplanada"
    },
    {
      "@value": "57276",
      "#text": "Espumoso"
    },
    {
      "@value": "57277",
      "#text": "Estância"
    },
    {
      "@value": "57278",
      "#text": "Estância Velha"
    },
    {
      "@value": "57279",
      "#text": "Esteio"
    },
    {
      "@value": "57280",
      "#text": "Estreito"
    },
    {
      "@value": "57281",
      "#text": "Estrela"
    },
    {
      "@value": "57282",
      "#text": "Euclides da Cunha"
    },
    {
      "@value": "57283",
      "#text": "Eusébio"
    },
    {
      "@value": "57284",
      "#text": "Extrema"
    },
    {
      "@value": "57285",
      "#text": "Extremoz"
    },
    {
      "@value": "57286",
      "#text": "Exu"
    },
    {
      "@value": "57287",
      "#text": "Fagundes"
    },
    {
      "@value": "57288",
      "#text": "Farias Brito"
    },
    {
      "@value": "57289",
      "#text": "Farroupilha"
    },
    {
      "@value": "57290",
      "#text": "Fartura"
    },
    {
      "@value": "57291",
      "#text": "Faxinal"
    },
    {
      "@value": "57292",
      "#text": "Feijó"
    },
    {
      "@value": "57293",
      "#text": "Feira de Santana"
    },
    {
      "@value": "57294",
      "#text": "Feira Nova"
    },
    {
      "@value": "31105",
      "#text": "Fernando De Noronha"
    },
    {
      "@value": "57295",
      "#text": "Fernando de Noronha (Distrito Estadual)"
    },
    {
      "@value": "57296",
      "#text": "Fernandópolis"
    },
    {
      "@value": "57297",
      "#text": "Ferraz de Vasconcelos"
    },
    {
      "@value": "57298",
      "#text": "Firminópolis"
    },
    {
      "@value": "57299",
      "#text": "Flexeiras"
    },
    {
      "@value": "57300",
      "#text": "Flores"
    },
    {
      "@value": "57301",
      "#text": "Flores da Cunha"
    },
    {
      "@value": "57302",
      "#text": "Floresta"
    },
    {
      "@value": "57303",
      "#text": "Florestópolis"
    },
    {
      "@value": "57304",
      "#text": "Floriano"
    },
    {
      "@value": "57305",
      "#text": "Florianópolis"
    },
    {
      "@value": "31104",
      "#text": "Florianopolis Aeroporto"
    },
    {
      "@value": "57306",
      "#text": "Fonte Boa"
    },
    {
      "@value": "57307",
      "#text": "Formiga"
    },
    {
      "@value": "57308",
      "#text": "Formosa"
    },
    {
      "@value": "57309",
      "#text": "Formosa do Rio Preto"
    },
    {
      "@value": "57310",
      "#text": "Forquilha"
    },
    {
      "@value": "57311",
      "#text": "Forquilhinha"
    },
    {
      "@value": "57312",
      "#text": "Fortaleza"
    },
    {
      "@value": "31106",
      "#text": "Fortaleza Aeropor-To"
    },
    {
      "@value": "57313",
      "#text": "Fortuna"
    },
    {
      "@value": "57314",
      "#text": "Foz do Iguaçu"
    },
    {
      "@value": "31103",
      "#text": "Foz Do Iguacu Aeroporto"
    },
    {
      "@value": "57315",
      "#text": "Franca"
    },
    {
      "@value": "57316",
      "#text": "Francisco Beltrão"
    },
    {
      "@value": "57317",
      "#text": "Francisco Morato"
    },
    {
      "@value": "57318",
      "#text": "Francisco Sá"
    },
    {
      "@value": "57319",
      "#text": "Franco da Rocha"
    },
    {
      "@value": "57320",
      "#text": "Frederico Westphalen"
    },
    {
      "@value": "57321",
      "#text": "Freguesia do Ribeirao da Ilha"
    },
    {
      "@value": "57322",
      "#text": "Frei Paulo"
    },
    {
      "@value": "33323",
      "#text": "Fronteira"
    },
    {
      "@value": "57323",
      "#text": "Frutal"
    },
    {
      "@value": "57324",
      "#text": "Fundão"
    },
    {
      "@value": "33324",
      "#text": "Furnas (Private)"
    },
    {
      "@value": "31108",
      "#text": "Galeao"
    },
    {
      "@value": "31107",
      "#text": "Gama"
    },
    {
      "@value": "57325",
      "#text": "Gameleira"
    },
    {
      "@value": "57326",
      "#text": "Gandu"
    },
    {
      "@value": "57327",
      "#text": "Garanhuns"
    },
    {
      "@value": "57328",
      "#text": "Gararu"
    },
    {
      "@value": "57329",
      "#text": "Garça"
    },
    {
      "@value": "57330",
      "#text": "Garibaldi"
    },
    {
      "@value": "57331",
      "#text": "Gaspar"
    },
    {
      "@value": "33326",
      "#text": "Gaviao Peixoto"
    },
    {
      "@value": "57332",
      "#text": "General Salgado"
    },
    {
      "@value": "57333",
      "#text": "Gilbués"
    },
    {
      "@value": "57334",
      "#text": "Girau do Ponciano"
    },
    {
      "@value": "57335",
      "#text": "Giruá"
    },
    {
      "@value": "57336",
      "#text": "Glória do Goitá"
    },
    {
      "@value": "57337",
      "#text": "Goiana"
    },
    {
      "@value": "57338",
      "#text": "Goianápolis"
    },
    {
      "@value": "57339",
      "#text": "Goianésia"
    },
    {
      "@value": "57340",
      "#text": "Goiânia"
    },
    {
      "@value": "31109",
      "#text": "Goiania Aeroporto"
    },
    {
      "@value": "57341",
      "#text": "Goianinha"
    },
    {
      "@value": "57342",
      "#text": "Goianira"
    },
    {
      "@value": "57343",
      "#text": "Goiás"
    },
    {
      "@value": "57344",
      "#text": "Goiatuba"
    },
    {
      "@value": "33328",
      "#text": "Gov Valadares"
    },
    {
      "@value": "57345",
      "#text": "Governador Dix Sept Rosado"
    },
    {
      "@value": "57346",
      "#text": "Governador Valadares"
    },
    {
      "@value": "57347",
      "#text": "Grajaú"
    },
    {
      "@value": "57348",
      "#text": "Granja"
    },
    {
      "@value": "57349",
      "#text": "Gravatá"
    },
    {
      "@value": "57350",
      "#text": "Gravataí"
    },
    {
      "@value": "57351",
      "#text": "Guabiraba"
    },
    {
      "@value": "57352",
      "#text": "Guaçuí"
    },
    {
      "@value": "57353",
      "#text": "Guaíba"
    },
    {
      "@value": "57354",
      "#text": "Guaíra"
    },
    {
      "@value": "57355",
      "#text": "Guaiúba"
    },
    {
      "@value": "57356",
      "#text": "Guajará"
    },
    {
      "@value": "57357",
      "#text": "Guajará Mirim"
    },
    {
      "@value": "57358",
      "#text": "Guanambi"
    },
    {
      "@value": "57359",
      "#text": "Guanhães"
    },
    {
      "@value": "57360",
      "#text": "Guapiaçu"
    },
    {
      "@value": "57361",
      "#text": "Guapimirim"
    },
    {
      "@value": "57362",
      "#text": "Guapó"
    },
    {
      "@value": "57363",
      "#text": "Guaporé"
    },
    {
      "@value": "57364",
      "#text": "Guará"
    },
    {
      "@value": "57365",
      "#text": "Guarabira"
    },
    {
      "@value": "57366",
      "#text": "Guaraciaba do Norte"
    },
    {
      "@value": "57367",
      "#text": "Guaramirim"
    },
    {
      "@value": "57368",
      "#text": "Guaranésia"
    },
    {
      "@value": "57369",
      "#text": "Guaraniaçu"
    },
    {
      "@value": "57370",
      "#text": "Guarapari"
    },
    {
      "@value": "57371",
      "#text": "Guarapuava"
    },
    {
      "@value": "57372",
      "#text": "Guararapes"
    },
    {
      "@value": "57373",
      "#text": "Guararema"
    },
    {
      "@value": "57374",
      "#text": "Guaratinga"
    },
    {
      "@value": "57375",
      "#text": "Guaratinguetá"
    },
    {
      "@value": "57376",
      "#text": "Guaratuba"
    },
    {
      "@value": "57377",
      "#text": "Guariba"
    },
    {
      "@value": "57378",
      "#text": "Guarujá"
    },
    {
      "@value": "57379",
      "#text": "Guarulhos"
    },
    {
      "@value": "31110",
      "#text": "Guarulhos Civ / Mil"
    },
    {
      "@value": "57380",
      "#text": "Guaxupé"
    },
    {
      "@value": "57381",
      "#text": "Guia Lopes da Laguna"
    },
    {
      "@value": "57382",
      "#text": "Guiratinga"
    },
    {
      "@value": "57383",
      "#text": "Gurinhém"
    },
    {
      "@value": "57384",
      "#text": "Gurupá"
    },
    {
      "@value": "57385",
      "#text": "Gurupi"
    },
    {
      "@value": "57386",
      "#text": "Herval"
    },
    {
      "@value": "57387",
      "#text": "Hidrolândia"
    },
    {
      "@value": "57388",
      "#text": "Horizonte"
    },
    {
      "@value": "57389",
      "#text": "Horizontina"
    },
    {
      "@value": "57390",
      "#text": "Hortolândia"
    },
    {
      "@value": "57391",
      "#text": "Humaitá"
    },
    {
      "@value": "57392",
      "#text": "Humberto de Campos"
    },
    {
      "@value": "57393",
      "#text": "Iaciara"
    },
    {
      "@value": "57394",
      "#text": "Iaçu"
    },
    {
      "@value": "31173",
      "#text": "Iauarete"
    },
    {
      "@value": "57395",
      "#text": "Ibaiti"
    },
    {
      "@value": "57396",
      "#text": "Ibaté"
    },
    {
      "@value": "57397",
      "#text": "Ibateguara"
    },
    {
      "@value": "57398",
      "#text": "Ibatiba"
    },
    {
      "@value": "57399",
      "#text": "Ibiá"
    },
    {
      "@value": "57400",
      "#text": "Ibiapina"
    },
    {
      "@value": "57401",
      "#text": "Ibicaraí"
    },
    {
      "@value": "57402",
      "#text": "Ibicuí"
    },
    {
      "@value": "57403",
      "#text": "Ibimirim"
    },
    {
      "@value": "57404",
      "#text": "Ibipeba"
    },
    {
      "@value": "57405",
      "#text": "Ibiporã"
    },
    {
      "@value": "57406",
      "#text": "Ibiraçu"
    },
    {
      "@value": "57407",
      "#text": "Ibirama"
    },
    {
      "@value": "57408",
      "#text": "Ibirapitanga"
    },
    {
      "@value": "57409",
      "#text": "Ibirataia"
    },
    {
      "@value": "57410",
      "#text": "Ibirité"
    },
    {
      "@value": "57411",
      "#text": "Ibirubá"
    },
    {
      "@value": "57412",
      "#text": "Ibitinga"
    },
    {
      "@value": "57413",
      "#text": "Ibiúna"
    },
    {
      "@value": "57414",
      "#text": "Ibotirama"
    },
    {
      "@value": "57415",
      "#text": "Içara"
    },
    {
      "@value": "57416",
      "#text": "Icatu"
    },
    {
      "@value": "57417",
      "#text": "Icó"
    },
    {
      "@value": "57418",
      "#text": "Iconha"
    },
    {
      "@value": "57419",
      "#text": "Igaci"
    },
    {
      "@value": "57420",
      "#text": "Igaraçu do Tietê"
    },
    {
      "@value": "57421",
      "#text": "Igarapava"
    },
    {
      "@value": "57422",
      "#text": "Igarapé"
    },
    {
      "@value": "57423",
      "#text": "Igarapé Açu"
    },
    {
      "@value": "57424",
      "#text": "Igarapé Miri"
    },
    {
      "@value": "57425",
      "#text": "Igarassu"
    },
    {
      "@value": "57426",
      "#text": "Igreja Nova"
    },
    {
      "@value": "57427",
      "#text": "Igrejinha"
    },
    {
      "@value": "57428",
      "#text": "Iguaba Grande"
    },
    {
      "@value": "57429",
      "#text": "Iguaí"
    },
    {
      "@value": "57430",
      "#text": "Iguape"
    },
    {
      "@value": "57431",
      "#text": "Iguatemi"
    },
    {
      "@value": "57432",
      "#text": "Iguatu"
    },
    {
      "@value": "57433",
      "#text": "Ijuí"
    },
    {
      "@value": "57434",
      "#text": "Ilha Solteira"
    },
    {
      "@value": "57435",
      "#text": "Ilhabela"
    },
    {
      "@value": "57436",
      "#text": "Ilhéus"
    },
    {
      "@value": "31114",
      "#text": "Ilheus Aeroporto"
    },
    {
      "@value": "57437",
      "#text": "Imaculada"
    },
    {
      "@value": "57438",
      "#text": "Imbituba"
    },
    {
      "@value": "57439",
      "#text": "Imbituva"
    },
    {
      "@value": "57440",
      "#text": "Imperatriz"
    },
    {
      "@value": "57441",
      "#text": "Indaial"
    },
    {
      "@value": "57442",
      "#text": "Indaiatuba"
    },
    {
      "@value": "57443",
      "#text": "Independência"
    },
    {
      "@value": "57444",
      "#text": "Indiaroba"
    },
    {
      "@value": "57445",
      "#text": "Ingá"
    },
    {
      "@value": "57446",
      "#text": "Inhambupe"
    },
    {
      "@value": "57447",
      "#text": "Inhapi"
    },
    {
      "@value": "57448",
      "#text": "Inhapim"
    },
    {
      "@value": "57449",
      "#text": "Inhuma"
    },
    {
      "@value": "57450",
      "#text": "Inhumas"
    },
    {
      "@value": "57451",
      "#text": "Ipaba"
    },
    {
      "@value": "57452",
      "#text": "Ipameri"
    },
    {
      "@value": "57453",
      "#text": "Ipanguaçu"
    },
    {
      "@value": "57454",
      "#text": "Ipatinga"
    },
    {
      "@value": "57455",
      "#text": "Ipauçu"
    },
    {
      "@value": "57456",
      "#text": "Iperó"
    },
    {
      "@value": "57457",
      "#text": "Ipiaú"
    },
    {
      "@value": "57458",
      "#text": "Ipirá"
    },
    {
      "@value": "57459",
      "#text": "Ipojuca"
    },
    {
      "@value": "57460",
      "#text": "Iporá"
    },
    {
      "@value": "57461",
      "#text": "Ipu"
    },
    {
      "@value": "57462",
      "#text": "Ipuã"
    },
    {
      "@value": "57463",
      "#text": "Ipubi"
    },
    {
      "@value": "57464",
      "#text": "Ipueiras"
    },
    {
      "@value": "57465",
      "#text": "Iracemápolis"
    },
    {
      "@value": "57466",
      "#text": "Iranduba"
    },
    {
      "@value": "57467",
      "#text": "Iraquara"
    },
    {
      "@value": "57468",
      "#text": "Irará"
    },
    {
      "@value": "57469",
      "#text": "Irati"
    },
    {
      "@value": "57470",
      "#text": "Irauçuba"
    },
    {
      "@value": "57471",
      "#text": "Irecê"
    },
    {
      "@value": "57472",
      "#text": "Irituia"
    },
    {
      "@value": "57473",
      "#text": "Irupi"
    },
    {
      "@value": "57474",
      "#text": "Itabaiana"
    },
    {
      "@value": "57475",
      "#text": "Itabaianinha"
    },
    {
      "@value": "57476",
      "#text": "Itaberá"
    },
    {
      "@value": "57477",
      "#text": "Itaberaba"
    },
    {
      "@value": "57478",
      "#text": "Itaberaí"
    },
    {
      "@value": "57479",
      "#text": "Itabira"
    },
    {
      "@value": "57480",
      "#text": "Itabirito"
    },
    {
      "@value": "57481",
      "#text": "Itaboraí"
    },
    {
      "@value": "57482",
      "#text": "Itabuna"
    },
    {
      "@value": "57483",
      "#text": "Itacarambi"
    },
    {
      "@value": "57484",
      "#text": "Itacaré"
    },
    {
      "@value": "57485",
      "#text": "Itacoatiara"
    },
    {
      "@value": "57486",
      "#text": "Itacorubi"
    },
    {
      "@value": "57487",
      "#text": "Itagi"
    },
    {
      "@value": "57488",
      "#text": "Itagibá"
    },
    {
      "@value": "57489",
      "#text": "Itaguaçu"
    },
    {
      "@value": "57490",
      "#text": "Itaguaí"
    },
    {
      "@value": "57491",
      "#text": "Itaí"
    },
    {
      "@value": "57492",
      "#text": "Itaíba"
    },
    {
      "@value": "57493",
      "#text": "Itainópolis"
    },
    {
      "@value": "57494",
      "#text": "Itaitinga"
    },
    {
      "@value": "57495",
      "#text": "Itaituba"
    },
    {
      "@value": "57496",
      "#text": "Itajaí"
    },
    {
      "@value": "57497",
      "#text": "Itajobi"
    },
    {
      "@value": "57498",
      "#text": "Itajubá"
    },
    {
      "@value": "57499",
      "#text": "Itajuípe"
    },
    {
      "@value": "57500",
      "#text": "Itamaracá"
    },
    {
      "@value": "57501",
      "#text": "Itamaraju"
    },
    {
      "@value": "57502",
      "#text": "Itamarandiba"
    },
    {
      "@value": "57503",
      "#text": "Itambacuri"
    },
    {
      "@value": "57504",
      "#text": "Itambé"
    },
    {
      "@value": "57505",
      "#text": "Itanhaém"
    },
    {
      "@value": "57506",
      "#text": "Itanhandu"
    },
    {
      "@value": "57507",
      "#text": "Itanhém"
    },
    {
      "@value": "57508",
      "#text": "Itaocara"
    },
    {
      "@value": "57509",
      "#text": "Itapaci"
    },
    {
      "@value": "57510",
      "#text": "Itapagé"
    },
    {
      "@value": "57511",
      "#text": "Itaparica"
    },
    {
      "@value": "57512",
      "#text": "Itapecerica"
    },
    {
      "@value": "57513",
      "#text": "Itapecerica da Serra"
    },
    {
      "@value": "57514",
      "#text": "Itapecuru Mirim"
    },
    {
      "@value": "57515",
      "#text": "Itapema"
    },
    {
      "@value": "57516",
      "#text": "Itapemirim"
    },
    {
      "@value": "57517",
      "#text": "Itaperuçu"
    },
    {
      "@value": "57518",
      "#text": "Itaperuna"
    },
    {
      "@value": "57519",
      "#text": "Itapetinga"
    },
    {
      "@value": "57520",
      "#text": "Itapetininga"
    },
    {
      "@value": "57521",
      "#text": "Itapeva"
    },
    {
      "@value": "57522",
      "#text": "Itapevi"
    },
    {
      "@value": "57523",
      "#text": "Itapicuru"
    },
    {
      "@value": "57524",
      "#text": "Itapipoca"
    },
    {
      "@value": "57525",
      "#text": "Itapira"
    },
    {
      "@value": "57526",
      "#text": "Itapirapuã"
    },
    {
      "@value": "57527",
      "#text": "Itapissuma"
    },
    {
      "@value": "57528",
      "#text": "Itápolis"
    },
    {
      "@value": "57529",
      "#text": "Itaporanga"
    },
    {
      "@value": "57530",
      "#text": "Itapuí"
    },
    {
      "@value": "57531",
      "#text": "Itapuranga"
    },
    {
      "@value": "57532",
      "#text": "Itaquaquecetuba"
    },
    {
      "@value": "57533",
      "#text": "Itaqui"
    },
    {
      "@value": "57534",
      "#text": "Itaquitinga"
    },
    {
      "@value": "57535",
      "#text": "Itarana"
    },
    {
      "@value": "57536",
      "#text": "Itarantim"
    },
    {
      "@value": "57537",
      "#text": "Itararé"
    },
    {
      "@value": "57538",
      "#text": "Itatiaia"
    },
    {
      "@value": "57539",
      "#text": "Itatiba"
    },
    {
      "@value": "57540",
      "#text": "Itatinga"
    },
    {
      "@value": "57541",
      "#text": "Itatuba"
    },
    {
      "@value": "57542",
      "#text": "Itaú de Minas"
    },
    {
      "@value": "57543",
      "#text": "Itaueira"
    },
    {
      "@value": "57544",
      "#text": "Itaúna"
    },
    {
      "@value": "57545",
      "#text": "Itinga"
    },
    {
      "@value": "57546",
      "#text": "Itirapina"
    },
    {
      "@value": "57547",
      "#text": "Itiruçu"
    },
    {
      "@value": "57548",
      "#text": "Itororó"
    },
    {
      "@value": "57549",
      "#text": "Itu"
    },
    {
      "@value": "57550",
      "#text": "Ituaçu"
    },
    {
      "@value": "57551",
      "#text": "Ituberá"
    },
    {
      "@value": "57552",
      "#text": "Ituiutaba"
    },
    {
      "@value": "57553",
      "#text": "Itumbiara"
    },
    {
      "@value": "57554",
      "#text": "Itupeva"
    },
    {
      "@value": "57555",
      "#text": "Itupiranga"
    },
    {
      "@value": "57556",
      "#text": "Iturama"
    },
    {
      "@value": "57557",
      "#text": "Ituverava"
    },
    {
      "@value": "57558",
      "#text": "Iúna"
    },
    {
      "@value": "57559",
      "#text": "Ivoti"
    },
    {
      "@value": "57560",
      "#text": "Jaboatão"
    },
    {
      "@value": "57561",
      "#text": "Jaboatão dos Guararapes"
    },
    {
      "@value": "57562",
      "#text": "Jaboticabal"
    },
    {
      "@value": "57563",
      "#text": "Jacaraú"
    },
    {
      "@value": "57564",
      "#text": "Jacareacanga"
    },
    {
      "@value": "57565",
      "#text": "Jacareí"
    },
    {
      "@value": "57566",
      "#text": "Jacarezinho"
    },
    {
      "@value": "57567",
      "#text": "Jaciara"
    },
    {
      "@value": "57568",
      "#text": "Jacobina"
    },
    {
      "@value": "57569",
      "#text": "Jacupiranga"
    },
    {
      "@value": "57570",
      "#text": "Jacutinga"
    },
    {
      "@value": "57571",
      "#text": "Jaguaquara"
    },
    {
      "@value": "57572",
      "#text": "Jaguarão"
    },
    {
      "@value": "57573",
      "#text": "Jaguarari"
    },
    {
      "@value": "57574",
      "#text": "Jaguariaíva"
    },
    {
      "@value": "57575",
      "#text": "Jaguaribe"
    },
    {
      "@value": "57576",
      "#text": "Jaguariúna"
    },
    {
      "@value": "57577",
      "#text": "Jaguaruana"
    },
    {
      "@value": "57578",
      "#text": "Jaguaruna"
    },
    {
      "@value": "57579",
      "#text": "Jaicós"
    },
    {
      "@value": "57580",
      "#text": "Jales"
    },
    {
      "@value": "57581",
      "#text": "Janaúba"
    },
    {
      "@value": "57582",
      "#text": "Jandaia do Sul"
    },
    {
      "@value": "57583",
      "#text": "Jandira"
    },
    {
      "@value": "57584",
      "#text": "Januária"
    },
    {
      "@value": "57585",
      "#text": "Japaratuba"
    },
    {
      "@value": "57586",
      "#text": "Japeri"
    },
    {
      "@value": "57587",
      "#text": "Japoatã"
    },
    {
      "@value": "57588",
      "#text": "Jaraguá"
    },
    {
      "@value": "57589",
      "#text": "Jaraguá do Sul"
    },
    {
      "@value": "122017",
      "#text": "Jaraguari"
    },
    {
      "@value": "57590",
      "#text": "Jardim"
    },
    {
      "@value": "57591",
      "#text": "Jardim de Piranhas"
    },
    {
      "@value": "57592",
      "#text": "Jardim do Seridó"
    },
    {
      "@value": "57593",
      "#text": "Jardinópolis"
    },
    {
      "@value": "57594",
      "#text": "Jarinu"
    },
    {
      "@value": "57595",
      "#text": "Jaru"
    },
    {
      "@value": "57596",
      "#text": "Jataí"
    },
    {
      "@value": "57597",
      "#text": "Jataizinho"
    },
    {
      "@value": "57598",
      "#text": "Jatobá"
    },
    {
      "@value": "57599",
      "#text": "Jaú"
    },
    {
      "@value": "57600",
      "#text": "Jequié"
    },
    {
      "@value": "57601",
      "#text": "Jequitinhonha"
    },
    {
      "@value": "57602",
      "#text": "Jeremoabo"
    },
    {
      "@value": "57603",
      "#text": "Jerônimo Monteiro"
    },
    {
      "@value": "57604",
      "#text": "Jetibá"
    },
    {
      "@value": "57605",
      "#text": "Jitaúna"
    },
    {
      "@value": "57606",
      "#text": "Joaçaba"
    },
    {
      "@value": "57607",
      "#text": "Joaíma"
    },
    {
      "@value": "57608",
      "#text": "Joanópolis"
    },
    {
      "@value": "57609",
      "#text": "João Alfredo"
    },
    {
      "@value": "57610",
      "#text": "João Câmara"
    },
    {
      "@value": "57611",
      "#text": "João Monlevade"
    },
    {
      "@value": "57612",
      "#text": "João Neiva"
    },
    {
      "@value": "57613",
      "#text": "João Pessoa"
    },
    {
      "@value": "57614",
      "#text": "João Pinheiro"
    },
    {
      "@value": "57615",
      "#text": "Joaquim Nabuco"
    },
    {
      "@value": "57616",
      "#text": "Joinville"
    },
    {
      "@value": "57617",
      "#text": "José Bonifácio"
    },
    {
      "@value": "57618",
      "#text": "José de Freitas"
    },
    {
      "@value": "57619",
      "#text": "Juatuba"
    },
    {
      "@value": "57620",
      "#text": "Juàzeirinho"
    },
    {
      "@value": "33332",
      "#text": "Juazeiro D Norte"
    },
    {
      "@value": "57621",
      "#text": "Juazeiro do Norte"
    },
    {
      "@value": "57622",
      "#text": "Jucás"
    },
    {
      "@value": "57623",
      "#text": "Jucurutu"
    },
    {
      "@value": "57624",
      "#text": "Juiz de Fora"
    },
    {
      "@value": "57625",
      "#text": "Júlio de Castilhos"
    },
    {
      "@value": "57626",
      "#text": "Jundiá"
    },
    {
      "@value": "57627",
      "#text": "Jundiaí"
    },
    {
      "@value": "57628",
      "#text": "Junqueiro"
    },
    {
      "@value": "57629",
      "#text": "Junqueirópolis"
    },
    {
      "@value": "57630",
      "#text": "Juquiá"
    },
    {
      "@value": "57631",
      "#text": "Juquitiba"
    },
    {
      "@value": "57632",
      "#text": "Juripiranga"
    },
    {
      "@value": "57633",
      "#text": "Juru"
    },
    {
      "@value": "57634",
      "#text": "Juruti"
    },
    {
      "@value": "57635",
      "#text": "Jutaí"
    },
    {
      "@value": "57636",
      "#text": "Ladário"
    },
    {
      "@value": "57637",
      "#text": "Lagarto"
    },
    {
      "@value": "57638",
      "#text": "Lages"
    },
    {
      "@value": "57639",
      "#text": "Lago da Pedra"
    },
    {
      "@value": "57640",
      "#text": "Lagoa"
    },
    {
      "@value": "57641",
      "#text": "Lagoa da Canoa"
    },
    {
      "@value": "57642",
      "#text": "Lagoa da Prata"
    },
    {
      "@value": "57643",
      "#text": "Lagoa do Itaenga"
    },
    {
      "@value": "57644",
      "#text": "Lagoa Formosa"
    },
    {
      "@value": "57645",
      "#text": "Lagoa Nova"
    },
    {
      "@value": "57646",
      "#text": "Lagoa Santa"
    },
    {
      "@value": "33338",
      "#text": "Lagoa Santa(Cv/M"
    },
    {
      "@value": "57647",
      "#text": "Lagoa Seca"
    },
    {
      "@value": "57648",
      "#text": "Lagoa Vermelha"
    },
    {
      "@value": "57649",
      "#text": "Laguna"
    },
    {
      "@value": "57650",
      "#text": "Laje"
    },
    {
      "@value": "57651",
      "#text": "Lajeado"
    },
    {
      "@value": "57652",
      "#text": "Lajedo"
    },
    {
      "@value": "57653",
      "#text": "Lajinha"
    },
    {
      "@value": "57654",
      "#text": "Lambari"
    },
    {
      "@value": "57655",
      "#text": "Lapa"
    },
    {
      "@value": "57656",
      "#text": "Lapão"
    },
    {
      "@value": "57657",
      "#text": "Laranja da Terra"
    },
    {
      "@value": "57658",
      "#text": "Laranjal Paulista"
    },
    {
      "@value": "57659",
      "#text": "Laranjeiras"
    },
    {
      "@value": "57660",
      "#text": "Laranjeiras do Sul"
    },
    {
      "@value": "57661",
      "#text": "Lauro de Freitas"
    },
    {
      "@value": "57662",
      "#text": "Lauro Muller"
    },
    {
      "@value": "57663",
      "#text": "Lavras"
    },
    {
      "@value": "57664",
      "#text": "Lavras da Mangabeira"
    },
    {
      "@value": "31149",
      "#text": "Leite Lopes / Ribeir"
    },
    {
      "@value": "57665",
      "#text": "Leme"
    },
    {
      "@value": "33335",
      "#text": "Lencois Chapada"
    },
    {
      "@value": "57666",
      "#text": "Lençóis Paulista"
    },
    {
      "@value": "57667",
      "#text": "Leopoldina"
    },
    {
      "@value": "57668",
      "#text": "Lima Duarte"
    },
    {
      "@value": "57669",
      "#text": "Limeira"
    },
    {
      "@value": "57670",
      "#text": "Limoeiro"
    },
    {
      "@value": "57671",
      "#text": "Limoeiro de Anadia"
    },
    {
      "@value": "57672",
      "#text": "Limoeiro do Ajuru"
    },
    {
      "@value": "57673",
      "#text": "Limoeiro do Norte"
    },
    {
      "@value": "57674",
      "#text": "Linhares"
    },
    {
      "@value": "57675",
      "#text": "Lins"
    },
    {
      "@value": "57676",
      "#text": "Livramento do Brumado"
    },
    {
      "@value": "57677",
      "#text": "Loanda"
    },
    {
      "@value": "57678",
      "#text": "Londrina"
    },
    {
      "@value": "31121",
      "#text": "Londrina Aeroporto"
    },
    {
      "@value": "57679",
      "#text": "Lorena"
    },
    {
      "@value": "57680",
      "#text": "Louveira"
    },
    {
      "@value": "57681",
      "#text": "Lucas"
    },
    {
      "@value": "57682",
      "#text": "Lucélia"
    },
    {
      "@value": "57683",
      "#text": "Luís Correia"
    },
    {
      "@value": "57684",
      "#text": "Luz"
    },
    {
      "@value": "57685",
      "#text": "Luziânia"
    },
    {
      "@value": "57686",
      "#text": "Luzilândia"
    },
    {
      "@value": "57687",
      "#text": "Macaé"
    },
    {
      "@value": "57688",
      "#text": "Macaíba"
    },
    {
      "@value": "31129",
      "#text": "Macapa"
    },
    {
      "@value": "57689",
      "#text": "Macapá (Estado do Amapa)"
    },
    {
      "@value": "57690",
      "#text": "Macaparana"
    },
    {
      "@value": "57691",
      "#text": "Macatuba"
    },
    {
      "@value": "57692",
      "#text": "Macau"
    },
    {
      "@value": "57693",
      "#text": "Macaúbas"
    },
    {
      "@value": "57694",
      "#text": "Maceió"
    },
    {
      "@value": "31128",
      "#text": "Maceio Aeroporto"
    },
    {
      "@value": "57695",
      "#text": "Machado"
    },
    {
      "@value": "57696",
      "#text": "Madre de Deus"
    },
    {
      "@value": "57697",
      "#text": "Mafra"
    },
    {
      "@value": "57698",
      "#text": "Mairi"
    },
    {
      "@value": "57699",
      "#text": "Mairinque"
    },
    {
      "@value": "57700",
      "#text": "Mairiporã"
    },
    {
      "@value": "57701",
      "#text": "Major Isidoro"
    },
    {
      "@value": "57702",
      "#text": "Malacacheta"
    },
    {
      "@value": "57703",
      "#text": "Malhador"
    },
    {
      "@value": "57704",
      "#text": "Mamanguape"
    },
    {
      "@value": "57705",
      "#text": "Manacapuru"
    },
    {
      "@value": "57706",
      "#text": "Manaíra"
    },
    {
      "@value": "57707",
      "#text": "Manaquiri"
    },
    {
      "@value": "57708",
      "#text": "Manaus"
    },
    {
      "@value": "31127",
      "#text": "Manaus Aeroporto"
    },
    {
      "@value": "57709",
      "#text": "Mâncio Lima"
    },
    {
      "@value": "57710",
      "#text": "Mandaguaçu"
    },
    {
      "@value": "57711",
      "#text": "Mandaguari"
    },
    {
      "@value": "57712",
      "#text": "Manga"
    },
    {
      "@value": "57713",
      "#text": "Mangaratiba"
    },
    {
      "@value": "57714",
      "#text": "Manhuaçu"
    },
    {
      "@value": "57715",
      "#text": "Manhumirim"
    },
    {
      "@value": "57716",
      "#text": "Manicoré"
    },
    {
      "@value": "57717",
      "#text": "Manoel Urbano"
    },
    {
      "@value": "57718",
      "#text": "Mantenópolis"
    },
    {
      "@value": "57719",
      "#text": "Mar de Espanha"
    },
    {
      "@value": "57720",
      "#text": "Maraã"
    },
    {
      "@value": "57721",
      "#text": "Marabá"
    },
    {
      "@value": "57722",
      "#text": "Maracaçumé"
    },
    {
      "@value": "57723",
      "#text": "Maracaí"
    },
    {
      "@value": "57724",
      "#text": "Maracaju"
    },
    {
      "@value": "57725",
      "#text": "Maracanã"
    },
    {
      "@value": "57726",
      "#text": "Maracanaú"
    },
    {
      "@value": "57727",
      "#text": "Maracás"
    },
    {
      "@value": "57728",
      "#text": "Maragogi"
    },
    {
      "@value": "57729",
      "#text": "Maragogipe"
    },
    {
      "@value": "57730",
      "#text": "Maraial"
    },
    {
      "@value": "31090",
      "#text": "Maranhao / Carolina Airport"
    },
    {
      "@value": "57731",
      "#text": "Marapanim"
    },
    {
      "@value": "57732",
      "#text": "Marataizes"
    },
    {
      "@value": "57733",
      "#text": "Marau"
    },
    {
      "@value": "57734",
      "#text": "Marco"
    },
    {
      "@value": "57735",
      "#text": "Marechal Cândido Rondon"
    },
    {
      "@value": "57736",
      "#text": "Marechal Deodoro"
    },
    {
      "@value": "57737",
      "#text": "Marechal Floriano"
    },
    {
      "@value": "57738",
      "#text": "Marechal Thaumaturgo"
    },
    {
      "@value": "57739",
      "#text": "Mari"
    },
    {
      "@value": "57740",
      "#text": "Marialva"
    },
    {
      "@value": "57741",
      "#text": "Mariana"
    },
    {
      "@value": "57742",
      "#text": "Maribondo"
    },
    {
      "@value": "57743",
      "#text": "Maricá"
    },
    {
      "@value": "57744",
      "#text": "Marilândia"
    },
    {
      "@value": "57745",
      "#text": "Marília"
    },
    {
      "@value": "57746",
      "#text": "Maringá"
    },
    {
      "@value": "33342",
      "#text": "Marlim P20 Oil"
    },
    {
      "@value": "31131",
      "#text": "Marte Civ / Mil"
    },
    {
      "@value": "57747",
      "#text": "Martinho Campos"
    },
    {
      "@value": "57748",
      "#text": "Martinópolis"
    },
    {
      "@value": "57749",
      "#text": "Maruim"
    },
    {
      "@value": "57750",
      "#text": "Mascote"
    },
    {
      "@value": "57751",
      "#text": "Massaranduba"
    },
    {
      "@value": "57752",
      "#text": "Mata de São João"
    },
    {
      "@value": "57753",
      "#text": "Mata Grande"
    },
    {
      "@value": "57754",
      "#text": "Matão"
    },
    {
      "@value": "57755",
      "#text": "Matelândia"
    },
    {
      "@value": "57756",
      "#text": "Mateus Leme"
    },
    {
      "@value": "57757",
      "#text": "Matias Barbosa"
    },
    {
      "@value": "57758",
      "#text": "Matias Olímpio"
    },
    {
      "@value": "57759",
      "#text": "Matinha"
    },
    {
      "@value": "57760",
      "#text": "Matipó"
    },
    {
      "@value": "57761",
      "#text": "Mato Verde"
    },
    {
      "@value": "57762",
      "#text": "Matozinhos"
    },
    {
      "@value": "57763",
      "#text": "Matriz de Camaragibe"
    },
    {
      "@value": "57764",
      "#text": "Mauá"
    },
    {
      "@value": "57765",
      "#text": "Maués"
    },
    {
      "@value": "57766",
      "#text": "Mazagão"
    },
    {
      "@value": "57767",
      "#text": "Medeiros Neto"
    },
    {
      "@value": "57768",
      "#text": "Medianeira"
    },
    {
      "@value": "57769",
      "#text": "Medina"
    },
    {
      "@value": "57770",
      "#text": "Mendes"
    },
    {
      "@value": "122036",
      "#text": "Meruoca"
    },
    {
      "@value": "57771",
      "#text": "Messias"
    },
    {
      "@value": "57772",
      "#text": "Miguel Alves"
    },
    {
      "@value": "57773",
      "#text": "Miguel Calmon"
    },
    {
      "@value": "57774",
      "#text": "Miguel Pereira"
    },
    {
      "@value": "57775",
      "#text": "Miguelópolis"
    },
    {
      "@value": "57776",
      "#text": "Milagres"
    },
    {
      "@value": "57777",
      "#text": "Mimoso do Sul"
    },
    {
      "@value": "33339",
      "#text": "Minacu"
    },
    {
      "@value": "57778",
      "#text": "Minador do Negrão"
    },
    {
      "@value": "57779",
      "#text": "Minas Novas"
    },
    {
      "@value": "57780",
      "#text": "Mineiros"
    },
    {
      "@value": "57781",
      "#text": "Mirabela"
    },
    {
      "@value": "57782",
      "#text": "Miracatu"
    },
    {
      "@value": "57783",
      "#text": "Miracema"
    },
    {
      "@value": "57784",
      "#text": "Mirador"
    },
    {
      "@value": "57785",
      "#text": "Miraí"
    },
    {
      "@value": "57786",
      "#text": "Miranda"
    },
    {
      "@value": "57787",
      "#text": "Mirandopólis"
    },
    {
      "@value": "57788",
      "#text": "Mirante do Paranapanema"
    },
    {
      "@value": "57789",
      "#text": "Missão Velha"
    },
    {
      "@value": "57790",
      "#text": "Mocajuba"
    },
    {
      "@value": "57791",
      "#text": "Mococa"
    },
    {
      "@value": "31130",
      "#text": "Mocoro / 17 Rosado"
    },
    {
      "@value": "57792",
      "#text": "Mogeiro"
    },
    {
      "@value": "57793",
      "#text": "Mogi das Cruzes"
    },
    {
      "@value": "57794",
      "#text": "Mogi Guaçu"
    },
    {
      "@value": "57795",
      "#text": "Mogi Mirim"
    },
    {
      "@value": "57796",
      "#text": "Moita Bonita"
    },
    {
      "@value": "57797",
      "#text": "Moju"
    },
    {
      "@value": "57798",
      "#text": "Mombaça"
    },
    {
      "@value": "57799",
      "#text": "Monção"
    },
    {
      "@value": "57800",
      "#text": "Mongaguá"
    },
    {
      "@value": "57801",
      "#text": "Monsenhor Gil"
    },
    {
      "@value": "57802",
      "#text": "Montanha"
    },
    {
      "@value": "57803",
      "#text": "Montanhas"
    },
    {
      "@value": "57804",
      "#text": "Monte Alegre"
    },
    {
      "@value": "57805",
      "#text": "Monte Alegre de Minas"
    },
    {
      "@value": "57806",
      "#text": "Monte Alegre de Sergipe"
    },
    {
      "@value": "57807",
      "#text": "Monte Alto"
    },
    {
      "@value": "57808",
      "#text": "Monte Aprazível"
    },
    {
      "@value": "57809",
      "#text": "Monte Azul"
    },
    {
      "@value": "57810",
      "#text": "Monte Azul Paulista"
    },
    {
      "@value": "57811",
      "#text": "Monte Carmelo"
    },
    {
      "@value": "33340",
      "#text": "Monte Dourado"
    },
    {
      "@value": "57812",
      "#text": "Monte Mor"
    },
    {
      "@value": "57813",
      "#text": "Monte Santo"
    },
    {
      "@value": "57814",
      "#text": "Monte Santo de Minas"
    },
    {
      "@value": "57815",
      "#text": "Monte Sião"
    },
    {
      "@value": "57816",
      "#text": "Monteiro"
    },
    {
      "@value": "57817",
      "#text": "Montes Altos"
    },
    {
      "@value": "57818",
      "#text": "Montes Claros"
    },
    {
      "@value": "57819",
      "#text": "Morada Nova"
    },
    {
      "@value": "57820",
      "#text": "Moreira Sales"
    },
    {
      "@value": "57821",
      "#text": "Moreno"
    },
    {
      "@value": "57822",
      "#text": "Morrinhos"
    },
    {
      "@value": "57823",
      "#text": "Morro Agudo"
    },
    {
      "@value": "57824",
      "#text": "Morro da Cruz"
    },
    {
      "@value": "57825",
      "#text": "Morro da Fumaça"
    },
    {
      "@value": "57826",
      "#text": "Morro do Chapéu"
    },
    {
      "@value": "57827",
      "#text": "Morros"
    },
    {
      "@value": "57828",
      "#text": "Mossoró"
    },
    {
      "@value": "57829",
      "#text": "Mozarlândia"
    },
    {
      "@value": "57830",
      "#text": "Muaná"
    },
    {
      "@value": "57831",
      "#text": "Mucuri"
    },
    {
      "@value": "57832",
      "#text": "Mucurici"
    },
    {
      "@value": "57833",
      "#text": "Mulungu"
    },
    {
      "@value": "57834",
      "#text": "Mundo Novo"
    },
    {
      "@value": "57835",
      "#text": "Muniz Freire"
    },
    {
      "@value": "57836",
      "#text": "Muriaé"
    },
    {
      "@value": "57837",
      "#text": "Murici"
    },
    {
      "@value": "57838",
      "#text": "Muritiba"
    },
    {
      "@value": "57839",
      "#text": "Mutuípe"
    },
    {
      "@value": "57840",
      "#text": "Mutum"
    },
    {
      "@value": "57841",
      "#text": "Muzambinho"
    },
    {
      "@value": "57842",
      "#text": "Nanuque"
    },
    {
      "@value": "57843",
      "#text": "Não Me Toque"
    },
    {
      "@value": "57844",
      "#text": "Natal"
    },
    {
      "@value": "31133",
      "#text": "Natal Aeroporto"
    },
    {
      "@value": "57845",
      "#text": "Natividade"
    },
    {
      "@value": "57846",
      "#text": "Natuba"
    },
    {
      "@value": "57847",
      "#text": "Navegantes"
    },
    {
      "@value": "57848",
      "#text": "Naviraí"
    },
    {
      "@value": "57849",
      "#text": "Nazaré"
    },
    {
      "@value": "57850",
      "#text": "Nazaré da Mata"
    },
    {
      "@value": "57851",
      "#text": "Neópolis"
    },
    {
      "@value": "57852",
      "#text": "Nepomuceno"
    },
    {
      "@value": "57853",
      "#text": "Nerópolis"
    },
    {
      "@value": "57854",
      "#text": "Nhamundá"
    },
    {
      "@value": "57855",
      "#text": "Nilópolis"
    },
    {
      "@value": "57856",
      "#text": "Niquelândia"
    },
    {
      "@value": "57857",
      "#text": "Nísia Floresta"
    },
    {
      "@value": "57858",
      "#text": "Niterói"
    },
    {
      "@value": "57859",
      "#text": "Nobres"
    },
    {
      "@value": "57860",
      "#text": "Nortelândia"
    },
    {
      "@value": "57861",
      "#text": "Nossa Senhora da Glória"
    },
    {
      "@value": "57862",
      "#text": "Nossa Senhora das Dores"
    },
    {
      "@value": "57863",
      "#text": "Nossa Senhora do Socorro"
    },
    {
      "@value": "57864",
      "#text": "Nova Aurora"
    },
    {
      "@value": "57865",
      "#text": "Nova Cruz"
    },
    {
      "@value": "57866",
      "#text": "Nova Era"
    },
    {
      "@value": "57867",
      "#text": "Nova Floresta"
    },
    {
      "@value": "57868",
      "#text": "Nova Friburgo"
    },
    {
      "@value": "57869",
      "#text": "Nova Granada"
    },
    {
      "@value": "57870",
      "#text": "Nova Iguaçu"
    },
    {
      "@value": "57871",
      "#text": "Nova Lima"
    },
    {
      "@value": "57872",
      "#text": "Nova Londrina"
    },
    {
      "@value": "57873",
      "#text": "Nova Odessa"
    },
    {
      "@value": "57874",
      "#text": "Nova Olímpia"
    },
    {
      "@value": "57875",
      "#text": "Nova Olinda do Norte"
    },
    {
      "@value": "57876",
      "#text": "Nova Petrópolis"
    },
    {
      "@value": "57877",
      "#text": "Nova Prata"
    },
    {
      "@value": "57878",
      "#text": "Nova Russas"
    },
    {
      "@value": "57879",
      "#text": "Nova Soure"
    },
    {
      "@value": "57880",
      "#text": "Nova Venécia"
    },
    {
      "@value": "57881",
      "#text": "Nova Viçosa"
    },
    {
      "@value": "57882",
      "#text": "Novo Aripuanã"
    },
    {
      "@value": "57883",
      "#text": "Novo Cruzeiro"
    },
    {
      "@value": "57884",
      "#text": "Novo Hamburgo"
    },
    {
      "@value": "57885",
      "#text": "Novo Horizonte"
    },
    {
      "@value": "57886",
      "#text": "Novo Lino"
    },
    {
      "@value": "57887",
      "#text": "Novo Oriente"
    },
    {
      "@value": "57888",
      "#text": "Ocara"
    },
    {
      "@value": "57889",
      "#text": "Oeiras"
    },
    {
      "@value": "57890",
      "#text": "Oeiras do Pará"
    },
    {
      "@value": "31134",
      "#text": "Oiapoque"
    },
    {
      "@value": "57891",
      "#text": "Olímpia"
    },
    {
      "@value": "57892",
      "#text": "Olinda"
    },
    {
      "@value": "57893",
      "#text": "Olindina"
    },
    {
      "@value": "57894",
      "#text": "Oliveira"
    },
    {
      "@value": "57895",
      "#text": "Oliveira dos Brejinhos"
    },
    {
      "@value": "57896",
      "#text": "Olivença"
    },
    {
      "@value": "33354",
      "#text": "Oriximina"
    },
    {
      "@value": "57897",
      "#text": "Oriximiná (Estado do Para)"
    },
    {
      "@value": "57898",
      "#text": "Orizona"
    },
    {
      "@value": "57899",
      "#text": "Orlândia"
    },
    {
      "@value": "57900",
      "#text": "Orleans"
    },
    {
      "@value": "57901",
      "#text": "Orobó"
    },
    {
      "@value": "57902",
      "#text": "Orós"
    },
    {
      "@value": "57903",
      "#text": "Ortigueira"
    },
    {
      "@value": "57904",
      "#text": "Osasco"
    },
    {
      "@value": "57905",
      "#text": "Osório"
    },
    {
      "@value": "57906",
      "#text": "Osvaldo Cruz"
    },
    {
      "@value": "57907",
      "#text": "Otacílio Costa"
    },
    {
      "@value": "57908",
      "#text": "Ourém"
    },
    {
      "@value": "57909",
      "#text": "Ouricuri"
    },
    {
      "@value": "57910",
      "#text": "Ourinhos"
    },
    {
      "@value": "57911",
      "#text": "Ouro Branco"
    },
    {
      "@value": "57912",
      "#text": "Ouro Preto"
    },
    {
      "@value": "57913",
      "#text": "Ouro Preto do Oeste"
    },
    {
      "@value": "57914",
      "#text": "Pacaembu"
    },
    {
      "@value": "57915",
      "#text": "Pacajus"
    },
    {
      "@value": "57916",
      "#text": "Pacatuba"
    },
    {
      "@value": "57917",
      "#text": "Padre Bernardo"
    },
    {
      "@value": "57918",
      "#text": "Padre Paraíso"
    },
    {
      "@value": "57919",
      "#text": "Paiçandu"
    },
    {
      "@value": "57920",
      "#text": "Palhoça"
    },
    {
      "@value": "57921",
      "#text": "Palmares"
    },
    {
      "@value": "57922",
      "#text": "Palmares do Sul"
    },
    {
      "@value": "57923",
      "#text": "Palmas"
    },
    {
      "@value": "57924",
      "#text": "Palmas de Monte Alto"
    },
    {
      "@value": "57925",
      "#text": "Palmeira"
    },
    {
      "@value": "57926",
      "#text": "Palmeira das Missões"
    },
    {
      "@value": "57927",
      "#text": "Palmeirais"
    },
    {
      "@value": "57928",
      "#text": "Palmeiras de Goiás"
    },
    {
      "@value": "57929",
      "#text": "Palmital"
    },
    {
      "@value": "57930",
      "#text": "Palotina"
    },
    {
      "@value": "57931",
      "#text": "Panambi"
    },
    {
      "@value": "57932",
      "#text": "Pancas"
    },
    {
      "@value": "57933",
      "#text": "Panorama"
    },
    {
      "@value": "57934",
      "#text": "Pantanal"
    },
    {
      "@value": "57935",
      "#text": "Pantano do Sul"
    },
    {
      "@value": "57936",
      "#text": "Pão de Açúcar"
    },
    {
      "@value": "57937",
      "#text": "Papagaios"
    },
    {
      "@value": "57938",
      "#text": "Pará de Minas"
    },
    {
      "@value": "57939",
      "#text": "Paracambi"
    },
    {
      "@value": "57940",
      "#text": "Paracatu"
    },
    {
      "@value": "57941",
      "#text": "Paracuru"
    },
    {
      "@value": "57942",
      "#text": "Paragominas"
    },
    {
      "@value": "57943",
      "#text": "Paraguaçu"
    },
    {
      "@value": "57944",
      "#text": "Paraguaçu Paulista"
    },
    {
      "@value": "57945",
      "#text": "Paraíba do Sul"
    },
    {
      "@value": "57946",
      "#text": "Paraibano"
    },
    {
      "@value": "57947",
      "#text": "Paraipaba"
    },
    {
      "@value": "57948",
      "#text": "Paraisópolis"
    },
    {
      "@value": "57949",
      "#text": "Parambu"
    },
    {
      "@value": "57950",
      "#text": "Paramirim"
    },
    {
      "@value": "57951",
      "#text": "Paranaguá"
    },
    {
      "@value": "57952",
      "#text": "Paranaíba"
    },
    {
      "@value": "57953",
      "#text": "Paranapanema"
    },
    {
      "@value": "57954",
      "#text": "Paranavaí"
    },
    {
      "@value": "57955",
      "#text": "Paranhos"
    },
    {
      "@value": "57956",
      "#text": "Parati"
    },
    {
      "@value": "57957",
      "#text": "Paratinga"
    },
    {
      "@value": "57958",
      "#text": "Paraúna"
    },
    {
      "@value": "57959",
      "#text": "Parelhas"
    },
    {
      "@value": "57960",
      "#text": "Parintins"
    },
    {
      "@value": "57961",
      "#text": "Paripiranga"
    },
    {
      "@value": "57962",
      "#text": "Pariquera Açu"
    },
    {
      "@value": "57963",
      "#text": "Parnaíba"
    },
    {
      "@value": "31136",
      "#text": "Parnaiba Aeroporto"
    },
    {
      "@value": "57964",
      "#text": "Parnamirim"
    },
    {
      "@value": "57965",
      "#text": "Parnarama"
    },
    {
      "@value": "57966",
      "#text": "Parobé"
    },
    {
      "@value": "57967",
      "#text": "Passa Quatro"
    },
    {
      "@value": "57968",
      "#text": "Passagem Franca"
    },
    {
      "@value": "57969",
      "#text": "Passira"
    },
    {
      "@value": "57970",
      "#text": "Passo Fundo"
    },
    {
      "@value": "57971",
      "#text": "Passos"
    },
    {
      "@value": "57972",
      "#text": "Pastos Bons"
    },
    {
      "@value": "57973",
      "#text": "Pato Branco"
    },
    {
      "@value": "57974",
      "#text": "Patos"
    },
    {
      "@value": "57975",
      "#text": "Patos de Minas"
    },
    {
      "@value": "57976",
      "#text": "Patrocínio"
    },
    {
      "@value": "57977",
      "#text": "Patu"
    },
    {
      "@value": "57978",
      "#text": "Paty do Alferes"
    },
    {
      "@value": "57979",
      "#text": "Pau Brasil"
    },
    {
      "@value": "57980",
      "#text": "Pauini"
    },
    {
      "@value": "57981",
      "#text": "Paulínia"
    },
    {
      "@value": "57982",
      "#text": "Paulista"
    },
    {
      "@value": "57983",
      "#text": "Paulista Flórida"
    },
    {
      "@value": "57984",
      "#text": "Paulo Afonso"
    },
    {
      "@value": "57985",
      "#text": "Paulo Ramos"
    },
    {
      "@value": "57986",
      "#text": "Peabiru"
    },
    {
      "@value": "57987",
      "#text": "Peçanha"
    },
    {
      "@value": "57988",
      "#text": "Pederneiras"
    },
    {
      "@value": "57989",
      "#text": "Pedra"
    },
    {
      "@value": "57990",
      "#text": "Pedra Azul"
    },
    {
      "@value": "57991",
      "#text": "Pedra Branca"
    },
    {
      "@value": "57992",
      "#text": "Pedras de Fogo"
    },
    {
      "@value": "57993",
      "#text": "Pedregulho"
    },
    {
      "@value": "57994",
      "#text": "Pedreira"
    },
    {
      "@value": "57995",
      "#text": "Pedro II"
    },
    {
      "@value": "57996",
      "#text": "Pedro Leopoldo"
    },
    {
      "@value": "57997",
      "#text": "Pedro Velho"
    },
    {
      "@value": "57998",
      "#text": "Pelotas"
    },
    {
      "@value": "57999",
      "#text": "Penalva"
    },
    {
      "@value": "58000",
      "#text": "Penápolis"
    },
    {
      "@value": "58001",
      "#text": "Pendências"
    },
    {
      "@value": "58002",
      "#text": "Penedo"
    },
    {
      "@value": "58003",
      "#text": "Penha"
    },
    {
      "@value": "58004",
      "#text": "Pentecoste"
    },
    {
      "@value": "58005",
      "#text": "Perdões"
    },
    {
      "@value": "58006",
      "#text": "Pereira Barreto"
    },
    {
      "@value": "58007",
      "#text": "Peruíbe"
    },
    {
      "@value": "58008",
      "#text": "Pesqueira"
    },
    {
      "@value": "58009",
      "#text": "Petrolina"
    },
    {
      "@value": "31141",
      "#text": "Petrolina Aeropor-To"
    },
    {
      "@value": "58010",
      "#text": "Petrolina de Goiás"
    },
    {
      "@value": "58011",
      "#text": "Petrópolis"
    },
    {
      "@value": "58012",
      "#text": "Piaçabuçu"
    },
    {
      "@value": "58013",
      "#text": "Piancó"
    },
    {
      "@value": "58014",
      "#text": "Picos"
    },
    {
      "@value": "58015",
      "#text": "Picuí"
    },
    {
      "@value": "58016",
      "#text": "Piedade"
    },
    {
      "@value": "58017",
      "#text": "Pilar"
    },
    {
      "@value": "58018",
      "#text": "Pilar do Sul"
    },
    {
      "@value": "58019",
      "#text": "Pimenta Bueno"
    },
    {
      "@value": "58020",
      "#text": "Pimenteiras"
    },
    {
      "@value": "58021",
      "#text": "Pindamonhangaba"
    },
    {
      "@value": "58022",
      "#text": "Pindaré Mirim"
    },
    {
      "@value": "58023",
      "#text": "Pindobaçu"
    },
    {
      "@value": "58024",
      "#text": "Pindorama"
    },
    {
      "@value": "58025",
      "#text": "Pinhais"
    },
    {
      "@value": "58026",
      "#text": "Pinhão"
    },
    {
      "@value": "58027",
      "#text": "Pinheiral"
    },
    {
      "@value": "58028",
      "#text": "Pinheiro"
    },
    {
      "@value": "58029",
      "#text": "Pinheiro Machado"
    },
    {
      "@value": "58030",
      "#text": "Pio IX"
    },
    {
      "@value": "58031",
      "#text": "Pio XII"
    },
    {
      "@value": "58032",
      "#text": "Piquete"
    },
    {
      "@value": "58033",
      "#text": "Piracaia"
    },
    {
      "@value": "58034",
      "#text": "Piracanjuba"
    },
    {
      "@value": "58035",
      "#text": "Piracicaba"
    },
    {
      "@value": "58036",
      "#text": "Piracuruca"
    },
    {
      "@value": "58037",
      "#text": "Piraí"
    },
    {
      "@value": "58038",
      "#text": "Piraí do Sul"
    },
    {
      "@value": "58039",
      "#text": "Piraju"
    },
    {
      "@value": "58040",
      "#text": "Pirajuí"
    },
    {
      "@value": "33320",
      "#text": "Pirana P15 Oil"
    },
    {
      "@value": "58041",
      "#text": "Piranhas"
    },
    {
      "@value": "58042",
      "#text": "Pirapemas"
    },
    {
      "@value": "58043",
      "#text": "Pirapora"
    },
    {
      "@value": "58044",
      "#text": "Pirapora do Bom Jesus"
    },
    {
      "@value": "58045",
      "#text": "Pirapozinho"
    },
    {
      "@value": "58046",
      "#text": "Piraquara"
    },
    {
      "@value": "58047",
      "#text": "Pirassununga"
    },
    {
      "@value": "58048",
      "#text": "Pirenópolis"
    },
    {
      "@value": "58049",
      "#text": "Pires do Rio"
    },
    {
      "@value": "58050",
      "#text": "Piripiri"
    },
    {
      "@value": "58051",
      "#text": "Piritiba"
    },
    {
      "@value": "58052",
      "#text": "Pirpirituba"
    },
    {
      "@value": "58053",
      "#text": "Pitanga"
    },
    {
      "@value": "58054",
      "#text": "Pitangueiras"
    },
    {
      "@value": "58055",
      "#text": "Pitangui"
    },
    {
      "@value": "58056",
      "#text": "Pitimbu"
    },
    {
      "@value": "58057",
      "#text": "Piuí"
    },
    {
      "@value": "58058",
      "#text": "Piúma"
    },
    {
      "@value": "58059",
      "#text": "Plácido de Castro"
    },
    {
      "@value": "58060",
      "#text": "Planaltina"
    },
    {
      "@value": "58061",
      "#text": "Poá"
    },
    {
      "@value": "58062",
      "#text": "Poção de Pedras"
    },
    {
      "@value": "58063",
      "#text": "Pocinhos"
    },
    {
      "@value": "58064",
      "#text": "Poço Branco"
    },
    {
      "@value": "58065",
      "#text": "Poço das Trincheiras"
    },
    {
      "@value": "58066",
      "#text": "Poço Verde"
    },
    {
      "@value": "58067",
      "#text": "Poções"
    },
    {
      "@value": "58068",
      "#text": "Poconé"
    },
    {
      "@value": "58069",
      "#text": "Poços de Caldas"
    },
    {
      "@value": "58070",
      "#text": "Pombal"
    },
    {
      "@value": "58071",
      "#text": "Pombos"
    },
    {
      "@value": "58072",
      "#text": "Pomerode"
    },
    {
      "@value": "58073",
      "#text": "Pompéia"
    },
    {
      "@value": "58074",
      "#text": "Pompéu"
    },
    {
      "@value": "58075",
      "#text": "Ponta Grossa"
    },
    {
      "@value": "58076",
      "#text": "Ponta Porã"
    },
    {
      "@value": "31143",
      "#text": "Ponta Pora Aeropor-To"
    },
    {
      "@value": "58077",
      "#text": "Pontal"
    },
    {
      "@value": "58078",
      "#text": "Pontal do Paraná"
    },
    {
      "@value": "58079",
      "#text": "Pontalina"
    },
    {
      "@value": "58080",
      "#text": "Ponte Nova"
    },
    {
      "@value": "58081",
      "#text": "Pontes e Lacerda"
    },
    {
      "@value": "58082",
      "#text": "Ponto Novo"
    },
    {
      "@value": "58083",
      "#text": "Porangatu"
    },
    {
      "@value": "58084",
      "#text": "Porciúncula"
    },
    {
      "@value": "58085",
      "#text": "Porecatu"
    },
    {
      "@value": "58086",
      "#text": "Portão"
    },
    {
      "@value": "58087",
      "#text": "Porteirinha"
    },
    {
      "@value": "58088",
      "#text": "Portel"
    },
    {
      "@value": "58089",
      "#text": "Porto"
    },
    {
      "@value": "58090",
      "#text": "Porto Alegre"
    },
    {
      "@value": "31135",
      "#text": "Porto Alegre Aero-Porto"
    },
    {
      "@value": "58091",
      "#text": "Pôrto Barra do Ivinheima"
    },
    {
      "@value": "58092",
      "#text": "Porto Belo"
    },
    {
      "@value": "58093",
      "#text": "Porto Calvo"
    },
    {
      "@value": "58094",
      "#text": "Porto da Folha"
    },
    {
      "@value": "121740",
      "#text": "Porto de Galinhas"
    },
    {
      "@value": "58095",
      "#text": "Porto de Moz"
    },
    {
      "@value": "58096",
      "#text": "Porto Feliz"
    },
    {
      "@value": "58097",
      "#text": "Porto Ferreira"
    },
    {
      "@value": "58098",
      "#text": "Porto Franco"
    },
    {
      "@value": "58099",
      "#text": "Porto Murtinho"
    },
    {
      "@value": "31142",
      "#text": "Porto Nacional Aeroporto"
    },
    {
      "@value": "58100",
      "#text": "Porto Real"
    },
    {
      "@value": "58101",
      "#text": "Porto Real do Colégio"
    },
    {
      "@value": "58102",
      "#text": "Porto Seguro"
    },
    {
      "@value": "58103",
      "#text": "Porto União"
    },
    {
      "@value": "58104",
      "#text": "Porto Velho"
    },
    {
      "@value": "31144",
      "#text": "Porto Velho Aeroporto"
    },
    {
      "@value": "58105",
      "#text": "Porto Walter"
    },
    {
      "@value": "58106",
      "#text": "Posse"
    },
    {
      "@value": "58107",
      "#text": "Pôsto Fiscal Rolim de Moura"
    },
    {
      "@value": "58108",
      "#text": "Pouso Alegre"
    },
    {
      "@value": "58109",
      "#text": "Poxoréo"
    },
    {
      "@value": "58110",
      "#text": "Prado"
    },
    {
      "@value": "58111",
      "#text": "Pradópolis"
    },
    {
      "@value": "58112",
      "#text": "Praia Grande"
    },
    {
      "@value": "58113",
      "#text": "Prainha"
    },
    {
      "@value": "58114",
      "#text": "Prata"
    },
    {
      "@value": "58115",
      "#text": "Presidente Bernardes"
    },
    {
      "@value": "58116",
      "#text": "Presidente Dutra"
    },
    {
      "@value": "58117",
      "#text": "Presidente Epitácio"
    },
    {
      "@value": "58118",
      "#text": "Presidente Médici"
    },
    {
      "@value": "58119",
      "#text": "Presidente Olegário"
    },
    {
      "@value": "58120",
      "#text": "Presidente Prudente"
    },
    {
      "@value": "58121",
      "#text": "Presidente Venceslau"
    },
    {
      "@value": "58122",
      "#text": "Princesa Isabel"
    },
    {
      "@value": "58123",
      "#text": "Promissão"
    },
    {
      "@value": "58124",
      "#text": "Propriá"
    },
    {
      "@value": "58125",
      "#text": "Prudentópolis"
    },
    {
      "@value": "58126",
      "#text": "Puxinanã"
    },
    {
      "@value": "58127",
      "#text": "Quaraí"
    },
    {
      "@value": "58128",
      "#text": "Quatá"
    },
    {
      "@value": "58129",
      "#text": "Quatis"
    },
    {
      "@value": "58130",
      "#text": "Quatro Barras"
    },
    {
      "@value": "58131",
      "#text": "Quebrangulo"
    },
    {
      "@value": "58132",
      "#text": "Queimadas"
    },
    {
      "@value": "58133",
      "#text": "Queimados"
    },
    {
      "@value": "58134",
      "#text": "Quijingue"
    },
    {
      "@value": "58135",
      "#text": "Quipapá"
    },
    {
      "@value": "58136",
      "#text": "Quirinópolis"
    },
    {
      "@value": "58137",
      "#text": "Quixadá"
    },
    {
      "@value": "58138",
      "#text": "Quixeramobim"
    },
    {
      "@value": "58139",
      "#text": "Quixeré"
    },
    {
      "@value": "58140",
      "#text": "Rancharia"
    },
    {
      "@value": "58141",
      "#text": "Raposa"
    },
    {
      "@value": "58142",
      "#text": "Raposos"
    },
    {
      "@value": "58143",
      "#text": "Realeza"
    },
    {
      "@value": "58144",
      "#text": "Recife"
    },
    {
      "@value": "31147",
      "#text": "Recife Aeroporto"
    },
    {
      "@value": "58145",
      "#text": "Recreio"
    },
    {
      "@value": "58146",
      "#text": "Redenção"
    },
    {
      "@value": "58147",
      "#text": "Regeneração"
    },
    {
      "@value": "58148",
      "#text": "Regente Feijó"
    },
    {
      "@value": "58149",
      "#text": "Registro"
    },
    {
      "@value": "58150",
      "#text": "Remígio"
    },
    {
      "@value": "58151",
      "#text": "Reriutaba"
    },
    {
      "@value": "58152",
      "#text": "Resende"
    },
    {
      "@value": "58153",
      "#text": "Reserva"
    },
    {
      "@value": "58154",
      "#text": "Resplendor"
    },
    {
      "@value": "58155",
      "#text": "Riachão"
    },
    {
      "@value": "58156",
      "#text": "Riachão das Neves"
    },
    {
      "@value": "58157",
      "#text": "Riachão do Dantas"
    },
    {
      "@value": "58158",
      "#text": "Riachão do Jacuípe"
    },
    {
      "@value": "58159",
      "#text": "Riacho de Santana"
    },
    {
      "@value": "58160",
      "#text": "Rialma"
    },
    {
      "@value": "58161",
      "#text": "Ribas do Rio Pardo"
    },
    {
      "@value": "58162",
      "#text": "Ribeira do Pombal"
    },
    {
      "@value": "58163",
      "#text": "Ribeirão"
    },
    {
      "@value": "58164",
      "#text": "Ribeirão Bonito"
    },
    {
      "@value": "58165",
      "#text": "Ribeirão Branco"
    },
    {
      "@value": "58166",
      "#text": "Ribeirão da Ilha"
    },
    {
      "@value": "58167",
      "#text": "Ribeirão das Neves"
    },
    {
      "@value": "58168",
      "#text": "Ribeirão Pires"
    },
    {
      "@value": "58169",
      "#text": "Ribeirão Preto"
    },
    {
      "@value": "58170",
      "#text": "Ribeirópolis"
    },
    {
      "@value": "31118",
      "#text": "Rio / Jacarepagua"
    },
    {
      "@value": "58171",
      "#text": "Rio Bananal"
    },
    {
      "@value": "58172",
      "#text": "Rio Bonito"
    },
    {
      "@value": "58173",
      "#text": "Rio Branco"
    },
    {
      "@value": "58174",
      "#text": "Rio Branco do Sul"
    },
    {
      "@value": "58175",
      "#text": "Rio Brilhante"
    },
    {
      "@value": "58176",
      "#text": "Rio Casca"
    },
    {
      "@value": "58177",
      "#text": "Rio Claro"
    },
    {
      "@value": "58178",
      "#text": "Rio das Ostras"
    },
    {
      "@value": "58179",
      "#text": "Rio das Pedras"
    },
    {
      "@value": "58180",
      "#text": "Río de Janeiro"
    },
    {
      "@value": "31148",
      "#text": "Rio De Janeiro Aeroporto"
    },
    {
      "@value": "58181",
      "#text": "Rio do Sul"
    },
    {
      "@value": "58182",
      "#text": "Rio Formoso"
    },
    {
      "@value": "58183",
      "#text": "Rio Grande"
    },
    {
      "@value": "58184",
      "#text": "Rio Grande da Serra"
    },
    {
      "@value": "58185",
      "#text": "Rio Largo"
    },
    {
      "@value": "58186",
      "#text": "Rio Negrinho"
    },
    {
      "@value": "58187",
      "#text": "Rio Negro"
    },
    {
      "@value": "58188",
      "#text": "Rio Novo"
    },
    {
      "@value": "58189",
      "#text": "Rio Novo do Sul"
    },
    {
      "@value": "58190",
      "#text": "Rio Pardo"
    },
    {
      "@value": "58191",
      "#text": "Rio Pardo de Minas"
    },
    {
      "@value": "58192",
      "#text": "Rio Piracicaba"
    },
    {
      "@value": "58193",
      "#text": "Rio Pomba"
    },
    {
      "@value": "58194",
      "#text": "Rio Preto da Eva"
    },
    {
      "@value": "58195",
      "#text": "Rio Real"
    },
    {
      "@value": "58196",
      "#text": "Rio Tavares"
    },
    {
      "@value": "58197",
      "#text": "Rio Tinto"
    },
    {
      "@value": "58198",
      "#text": "Rio Verde de Mato Grosso"
    },
    {
      "@value": "58199",
      "#text": "Rolândia"
    },
    {
      "@value": "58200",
      "#text": "Rolante"
    },
    {
      "@value": "58201",
      "#text": "Rondonópolis"
    },
    {
      "@value": "58202",
      "#text": "Rosário do Sul"
    },
    {
      "@value": "58203",
      "#text": "Rosário Oeste"
    },
    {
      "@value": "58204",
      "#text": "Rubiataba"
    },
    {
      "@value": "58205",
      "#text": "Russas"
    },
    {
      "@value": "58206",
      "#text": "Ruy Barbosa"
    },
    {
      "@value": "31102",
      "#text": "S. P. Aldeia Aerodrome"
    },
    {
      "@value": "58207",
      "#text": "Sabinópolis"
    },
    {
      "@value": "58208",
      "#text": "Saco dos Limoes"
    },
    {
      "@value": "58209",
      "#text": "Sacramento"
    },
    {
      "@value": "58210",
      "#text": "Salgado"
    },
    {
      "@value": "58211",
      "#text": "Salgado de São Félix"
    },
    {
      "@value": "58212",
      "#text": "Salgueiro"
    },
    {
      "@value": "58213",
      "#text": "Salinas"
    },
    {
      "@value": "58214",
      "#text": "Salinópolis"
    },
    {
      "@value": "58215",
      "#text": "Salto"
    },
    {
      "@value": "58216",
      "#text": "Salto de Pirapora"
    },
    {
      "@value": "58217",
      "#text": "Salvador"
    },
    {
      "@value": "31159",
      "#text": "Salvador Aeroporto"
    },
    {
      "@value": "58218",
      "#text": "Sananduva"
    },
    {
      "@value": "58219",
      "#text": "Santa Adélia"
    },
    {
      "@value": "58220",
      "#text": "Santa Bárbara d'Oeste"
    },
    {
      "@value": "58221",
      "#text": "Santa Branca"
    },
    {
      "@value": "58222",
      "#text": "Santa Cecília"
    },
    {
      "@value": "58223",
      "#text": "Santa Cruz"
    },
    {
      "@value": "31152",
      "#text": "Santa Cruz Aeropor-To"
    },
    {
      "@value": "58224",
      "#text": "Santa Cruz Cabrália"
    },
    {
      "@value": "58225",
      "#text": "Santa Cruz das Palmeiras"
    },
    {
      "@value": "58226",
      "#text": "Santa Cruz do Capibaribe"
    },
    {
      "@value": "58227",
      "#text": "Santa Cruz do Rio Pardo"
    },
    {
      "@value": "58228",
      "#text": "Santa Cruz do Sul"
    },
    {
      "@value": "58229",
      "#text": "Santa Fé do Sul"
    },
    {
      "@value": "58230",
      "#text": "Santa Gertrudes"
    },
    {
      "@value": "58231",
      "#text": "Santa Helena"
    },
    {
      "@value": "58232",
      "#text": "Santa Helena de Goiás"
    },
    {
      "@value": "58233",
      "#text": "Santa Inês"
    },
    {
      "@value": "58234",
      "#text": "Santa Isabel"
    },
    {
      "@value": "58235",
      "#text": "Santa Isabel do Rio Negro"
    },
    {
      "@value": "33352",
      "#text": "Santa Isabel Mor"
    },
    {
      "@value": "58236",
      "#text": "Santa Leopoldina"
    },
    {
      "@value": "58237",
      "#text": "Santa Luzia"
    },
    {
      "@value": "58238",
      "#text": "Santa Maria"
    },
    {
      "@value": "31155",
      "#text": "Santa Maria Aero-Porto"
    },
    {
      "@value": "58239",
      "#text": "Santa Maria da Boa Vista"
    },
    {
      "@value": "58240",
      "#text": "Santa Maria da Vitória"
    },
    {
      "@value": "58241",
      "#text": "Santa Maria do Pará"
    },
    {
      "@value": "58242",
      "#text": "Santa Maria do Suaçuí"
    },
    {
      "@value": "58243",
      "#text": "Santa Monica"
    },
    {
      "@value": "58244",
      "#text": "Santa Quitéria"
    },
    {
      "@value": "58245",
      "#text": "Santa Quitéria do Maranhão"
    },
    {
      "@value": "58246",
      "#text": "Santa Rita"
    },
    {
      "@value": "58247",
      "#text": "Santa Rita do Passa Quatro"
    },
    {
      "@value": "58248",
      "#text": "Santa Rita do Sapucaí"
    },
    {
      "@value": "58249",
      "#text": "Santa Rosa"
    },
    {
      "@value": "58250",
      "#text": "Santa Rosa de Viterbo"
    },
    {
      "@value": "58251",
      "#text": "Santa Teresa"
    },
    {
      "@value": "58252",
      "#text": "Santa Vitória"
    },
    {
      "@value": "58253",
      "#text": "Santa Vitória do Palmar"
    },
    {
      "@value": "58254",
      "#text": "Santaluz"
    },
    {
      "@value": "58255",
      "#text": "Santana"
    },
    {
      "@value": "58256",
      "#text": "Santana de Parnaíba"
    },
    {
      "@value": "58257",
      "#text": "Santana do Acaraú"
    },
    {
      "@value": "58258",
      "#text": "Santana do Ipanema"
    },
    {
      "@value": "58259",
      "#text": "Santana do Livramento"
    },
    {
      "@value": "58260",
      "#text": "Santana do Matos"
    },
    {
      "@value": "58261",
      "#text": "Santana do Mundaú"
    },
    {
      "@value": "58262",
      "#text": "Santana do Paraíso"
    },
    {
      "@value": "58263",
      "#text": "Santarém"
    },
    {
      "@value": "31156",
      "#text": "Santarem-Aeroporto"
    },
    {
      "@value": "58264",
      "#text": "Santiago"
    },
    {
      "@value": "58265",
      "#text": "Santo Amaro"
    },
    {
      "@value": "58266",
      "#text": "Santo Amaro da Imperatriz"
    },
    {
      "@value": "58267",
      "#text": "Santo Amaro das Brotas"
    },
    {
      "@value": "58268",
      "#text": "Santo Anastácio"
    },
    {
      "@value": "58269",
      "#text": "Santo André"
    },
    {
      "@value": "58270",
      "#text": "Santo Ângelo"
    },
    {
      "@value": "33345",
      "#text": "Santo Angelo Arp"
    },
    {
      "@value": "58271",
      "#text": "Santo Antônio"
    },
    {
      "@value": "58272",
      "#text": "Santo Antônio da Platina"
    },
    {
      "@value": "58273",
      "#text": "Santo Antônio de Jesus"
    },
    {
      "@value": "58274",
      "#text": "Santo Antônio de Pádua"
    },
    {
      "@value": "58275",
      "#text": "Santo Antônio de Posse"
    },
    {
      "@value": "58276",
      "#text": "Santo Antônio do Amparo"
    },
    {
      "@value": "58277",
      "#text": "Santo Antônio do Içá"
    },
    {
      "@value": "58278",
      "#text": "Santo Antônio do Leverger"
    },
    {
      "@value": "58279",
      "#text": "Santo Antônio do Monte"
    },
    {
      "@value": "58280",
      "#text": "Santo Antônio do Sudoeste"
    },
    {
      "@value": "58281",
      "#text": "Santo Antônio do Tauá"
    },
    {
      "@value": "58282",
      "#text": "Santo Augusto"
    },
    {
      "@value": "58283",
      "#text": "Santo Estêvão"
    },
    {
      "@value": "58284",
      "#text": "Santos"
    },
    {
      "@value": "31158",
      "#text": "Santos Aeroporto"
    },
    {
      "@value": "58285",
      "#text": "Santos Dumont"
    },
    {
      "@value": "58286",
      "#text": "São Benedito do Rio Preto"
    },
    {
      "@value": "58287",
      "#text": "São Bento"
    },
    {
      "@value": "58288",
      "#text": "São Bento do Sul"
    },
    {
      "@value": "58289",
      "#text": "São Bernardo"
    },
    {
      "@value": "58290",
      "#text": "São Bernardo do Campo"
    },
    {
      "@value": "58291",
      "#text": "São Borja"
    },
    {
      "@value": "58292",
      "#text": "São Caetano de Odivelas"
    },
    {
      "@value": "58293",
      "#text": "São Caetano do Sul"
    },
    {
      "@value": "58294",
      "#text": "São Carlos"
    },
    {
      "@value": "58295",
      "#text": "São Cristóvão"
    },
    {
      "@value": "58296",
      "#text": "São Desidério"
    },
    {
      "@value": "58297",
      "#text": "São Domingos"
    },
    {
      "@value": "58298",
      "#text": "São Domingos do Maranhão"
    },
    {
      "@value": "58299",
      "#text": "São Domingos do Prata"
    },
    {
      "@value": "58300",
      "#text": "São Felipe"
    },
    {
      "@value": "58301",
      "#text": "São Félix do Xingu"
    },
    {
      "@value": "58302",
      "#text": "São Fidélis"
    },
    {
      "@value": "58303",
      "#text": "São Francisco"
    },
    {
      "@value": "58304",
      "#text": "São Francisco de Assis"
    },
    {
      "@value": "58305",
      "#text": "São Francisco de Paula"
    },
    {
      "@value": "58306",
      "#text": "São Francisco do Conde"
    },
    {
      "@value": "58307",
      "#text": "São Francisco do Sul"
    },
    {
      "@value": "58308",
      "#text": "São Gabriel"
    },
    {
      "@value": "58309",
      "#text": "São Gabriel da Cachoeira"
    },
    {
      "@value": "58310",
      "#text": "São Geraldo do Araguaia"
    },
    {
      "@value": "58311",
      "#text": "São Gonçalo do Amarante"
    },
    {
      "@value": "58312",
      "#text": "São Gonçalo do Sapucaí"
    },
    {
      "@value": "58313",
      "#text": "São Gonçalo dos Campos"
    },
    {
      "@value": "58314",
      "#text": "São Gotardo"
    },
    {
      "@value": "58315",
      "#text": "São Jerônimo"
    },
    {
      "@value": "58316",
      "#text": "São João"
    },
    {
      "@value": "58317",
      "#text": "São João Batista"
    },
    {
      "@value": "58318",
      "#text": "São João da Barra"
    },
    {
      "@value": "58319",
      "#text": "São João da Boa Vista"
    },
    {
      "@value": "58320",
      "#text": "São João da Ponte"
    },
    {
      "@value": "58321",
      "#text": "São João de Meriti"
    },
    {
      "@value": "58322",
      "#text": "São João de Pirabas"
    },
    {
      "@value": "58323",
      "#text": "São João del Rei"
    },
    {
      "@value": "58324",
      "#text": "São João do Paraíso"
    },
    {
      "@value": "58325",
      "#text": "São João do Piauí"
    },
    {
      "@value": "58326",
      "#text": "São João dos Inhamuns"
    },
    {
      "@value": "58327",
      "#text": "São João dos Patos"
    },
    {
      "@value": "58328",
      "#text": "São João Evangelista"
    },
    {
      "@value": "58329",
      "#text": "São João Nepomuceno"
    },
    {
      "@value": "58330",
      "#text": "São Joaquim"
    },
    {
      "@value": "58331",
      "#text": "São Joaquim da Barra"
    },
    {
      "@value": "58332",
      "#text": "São Joaquim de Bicas"
    },
    {
      "@value": "58333",
      "#text": "São Joaquim do Monte"
    },
    {
      "@value": "58334",
      "#text": "São José"
    },
    {
      "@value": "58335",
      "#text": "São José da Coroa Grande"
    },
    {
      "@value": "58336",
      "#text": "São José da Laje"
    },
    {
      "@value": "58337",
      "#text": "São José da Tapera"
    },
    {
      "@value": "58338",
      "#text": "São José de Mipibu"
    },
    {
      "@value": "58339",
      "#text": "São José de Piranhas"
    },
    {
      "@value": "58340",
      "#text": "São José de Ribamar"
    },
    {
      "@value": "58341",
      "#text": "São José do Belmonte"
    },
    {
      "@value": "58342",
      "#text": "São José do Calçado"
    },
    {
      "@value": "58343",
      "#text": "São José do Campestre"
    },
    {
      "@value": "58344",
      "#text": "São José do Egito"
    },
    {
      "@value": "58345",
      "#text": "S��o José do Rio Pardo"
    },
    {
      "@value": "58346",
      "#text": "São José do Rio Preto"
    },
    {
      "@value": "31153",
      "#text": "Sao Jose Dos Campo"
    },
    {
      "@value": "58347",
      "#text": "São José dos Campos"
    },
    {
      "@value": "58348",
      "#text": "São José dos Pinhais"
    },
    {
      "@value": "33351",
      "#text": "Sao Jose Preto"
    },
    {
      "@value": "58349",
      "#text": "São Leopoldo"
    },
    {
      "@value": "58350",
      "#text": "São Lourenço"
    },
    {
      "@value": "58351",
      "#text": "São Lourenço da Mata"
    },
    {
      "@value": "58352",
      "#text": "São Lourenço da Serra"
    },
    {
      "@value": "58353",
      "#text": "São Lourenço do Sul"
    },
    {
      "@value": "58354",
      "#text": "São Lourenço dOeste"
    },
    {
      "@value": "58355",
      "#text": "São Luís"
    },
    {
      "@value": "58356",
      "#text": "São Luís de Montes Belos"
    },
    {
      "@value": "58357",
      "#text": "São Luís do Quitunde"
    },
    {
      "@value": "31154",
      "#text": "Sao Luiz Aeroporto"
    },
    {
      "@value": "58358",
      "#text": "São Luiz Gonzaga"
    },
    {
      "@value": "58359",
      "#text": "São Manuel"
    },
    {
      "@value": "58360",
      "#text": "São Marcos"
    },
    {
      "@value": "58361",
      "#text": "São Mateus"
    },
    {
      "@value": "58362",
      "#text": "São Mateus do Maranhão"
    },
    {
      "@value": "58363",
      "#text": "São Mateus do Sul"
    },
    {
      "@value": "58364",
      "#text": "São Miguel"
    },
    {
      "@value": "58365",
      "#text": "São Miguel do Araguaia"
    },
    {
      "@value": "58366",
      "#text": "São Miguel do Guamá"
    },
    {
      "@value": "58367",
      "#text": "São Miguel do Iguaçu"
    },
    {
      "@value": "58368",
      "#text": "São Miguel do Tapuio"
    },
    {
      "@value": "58369",
      "#text": "São Miguel dos Campos"
    },
    {
      "@value": "58370",
      "#text": "São Paulo"
    },
    {
      "@value": "31157",
      "#text": "Sao Paulo Aeropor-To"
    },
    {
      "@value": "58371",
      "#text": "São Paulo de Olivença"
    },
    {
      "@value": "58372",
      "#text": "São Paulo do Potengi"
    },
    {
      "@value": "58373",
      "#text": "São Pedro"
    },
    {
      "@value": "58374",
      "#text": "São Pedro da Aldeia"
    },
    {
      "@value": "58375",
      "#text": "São Pedro do Piauí"
    },
    {
      "@value": "58376",
      "#text": "São Pedro do Sul"
    },
    {
      "@value": "58377",
      "#text": "São Raimundo das Mangabeiras"
    },
    {
      "@value": "58378",
      "#text": "São Raimundo Nonato"
    },
    {
      "@value": "58379",
      "#text": "São Roque"
    },
    {
      "@value": "33350",
      "#text": "Sao Roque/S. Pau"
    },
    {
      "@value": "58380",
      "#text": "São Sebastião"
    },
    {
      "@value": "58381",
      "#text": "São Sebastião do Caí"
    },
    {
      "@value": "58382",
      "#text": "São Sebastião do Paraíso"
    },
    {
      "@value": "58383",
      "#text": "São Sebastião do Passé"
    },
    {
      "@value": "58384",
      "#text": "São Sepé"
    },
    {
      "@value": "58385",
      "#text": "São Simão"
    },
    {
      "@value": "58386",
      "#text": "São Tomé"
    },
    {
      "@value": "58387",
      "#text": "São Vicente"
    },
    {
      "@value": "58388",
      "#text": "Sapiranga"
    },
    {
      "@value": "58389",
      "#text": "Sapucaia"
    },
    {
      "@value": "58390",
      "#text": "Saquarema"
    },
    {
      "@value": "58391",
      "#text": "Sarandi"
    },
    {
      "@value": "58392",
      "#text": "Sarzedo"
    },
    {
      "@value": "58393",
      "#text": "Satuba"
    },
    {
      "@value": "58394",
      "#text": "Saubara"
    },
    {
      "@value": "58395",
      "#text": "Schroeder"
    },
    {
      "@value": "58396",
      "#text": "Seabra"
    },
    {
      "@value": "58397",
      "#text": "Sena Madureira"
    },
    {
      "@value": "58398",
      "#text": "Senador Canedo"
    },
    {
      "@value": "58399",
      "#text": "Senador Guiomard"
    },
    {
      "@value": "58400",
      "#text": "Senador José Porfírio"
    },
    {
      "@value": "58401",
      "#text": "Senador Pompeu"
    },
    {
      "@value": "58402",
      "#text": "Sengés"
    },
    {
      "@value": "58403",
      "#text": "Senhor do Bonfim"
    },
    {
      "@value": "58404",
      "#text": "Seropédica"
    },
    {
      "@value": "58405",
      "#text": "Serra"
    },
    {
      "@value": "58406",
      "#text": "Serra Branca"
    },
    {
      "@value": "58407",
      "#text": "Serra Negra"
    },
    {
      "@value": "58408",
      "#text": "Serra Talhada"
    },
    {
      "@value": "58409",
      "#text": "Serrana"
    },
    {
      "@value": "58410",
      "#text": "Serrinha"
    },
    {
      "@value": "58411",
      "#text": "Serro"
    },
    {
      "@value": "58412",
      "#text": "Sertânia"
    },
    {
      "@value": "58413",
      "#text": "Sertanópolis"
    },
    {
      "@value": "58414",
      "#text": "Sertãozinho"
    },
    {
      "@value": "58415",
      "#text": "Sete Lagoas"
    },
    {
      "@value": "58416",
      "#text": "Severiano Melo"
    },
    {
      "@value": "58417",
      "#text": "Severínia"
    },
    {
      "@value": "58418",
      "#text": "Siderópolis"
    },
    {
      "@value": "58419",
      "#text": "Sidrolândia"
    },
    {
      "@value": "58420",
      "#text": "Silva Jardim"
    },
    {
      "@value": "58421",
      "#text": "Silvânia"
    },
    {
      "@value": "58422",
      "#text": "Simão Dias"
    },
    {
      "@value": "58423",
      "#text": "Simões"
    },
    {
      "@value": "58424",
      "#text": "Simões Filho"
    },
    {
      "@value": "58425",
      "#text": "Simplício Mendes"
    },
    {
      "@value": "58426",
      "#text": "Siqueira Campos"
    },
    {
      "@value": "58427",
      "#text": "Sobradinho"
    },
    {
      "@value": "58428",
      "#text": "Sobral"
    },
    {
      "@value": "58429",
      "#text": "Socorro"
    },
    {
      "@value": "58430",
      "#text": "Solânea"
    },
    {
      "@value": "58431",
      "#text": "Soledade"
    },
    {
      "@value": "58432",
      "#text": "Sombrio"
    },
    {
      "@value": "58433",
      "#text": "Sorocaba"
    },
    {
      "@value": "58434",
      "#text": "Soure"
    },
    {
      "@value": "58435",
      "#text": "Sousa"
    },
    {
      "@value": "58436",
      "#text": "Sumaré"
    },
    {
      "@value": "58437",
      "#text": "Sumé"
    },
    {
      "@value": "58438",
      "#text": "Surubim"
    },
    {
      "@value": "58439",
      "#text": "Suzano"
    },
    {
      "@value": "31163",
      "#text": "Tabatinga"
    },
    {
      "@value": "58440",
      "#text": "Tabatinga (Amazonas)"
    },
    {
      "@value": "58441",
      "#text": "Tabira"
    },
    {
      "@value": "58442",
      "#text": "Taboão da Serra"
    },
    {
      "@value": "58443",
      "#text": "Taguatinga"
    },
    {
      "@value": "58444",
      "#text": "Taiobeiras"
    },
    {
      "@value": "58445",
      "#text": "Taipu"
    },
    {
      "@value": "58446",
      "#text": "Tamandaré"
    },
    {
      "@value": "58447",
      "#text": "Tambaú"
    },
    {
      "@value": "58448",
      "#text": "Tamboril"
    },
    {
      "@value": "58449",
      "#text": "Tanabi"
    },
    {
      "@value": "58450",
      "#text": "Tangará"
    },
    {
      "@value": "58451",
      "#text": "Tanguá"
    },
    {
      "@value": "58452",
      "#text": "Tanhaçu"
    },
    {
      "@value": "58453",
      "#text": "Tapejara"
    },
    {
      "@value": "58454",
      "#text": "Tapera"
    },
    {
      "@value": "58455",
      "#text": "Taperoá"
    },
    {
      "@value": "58456",
      "#text": "Tapes"
    },
    {
      "@value": "58457",
      "#text": "Tapiramutá"
    },
    {
      "@value": "58458",
      "#text": "Taquara"
    },
    {
      "@value": "58459",
      "#text": "Taquarana"
    },
    {
      "@value": "58460",
      "#text": "Taquari"
    },
    {
      "@value": "58461",
      "#text": "Taquaritinga"
    },
    {
      "@value": "58462",
      "#text": "Taquaritinga do Norte"
    },
    {
      "@value": "58463",
      "#text": "Taquarituba"
    },
    {
      "@value": "58464",
      "#text": "Tarauacá"
    },
    {
      "@value": "58465",
      "#text": "Tarumã"
    },
    {
      "@value": "58466",
      "#text": "Tatuí"
    },
    {
      "@value": "58467",
      "#text": "Taubaté"
    },
    {
      "@value": "33353",
      "#text": "Taubate (Base)"
    },
    {
      "@value": "58468",
      "#text": "Tavares"
    },
    {
      "@value": "58469",
      "#text": "Tefé"
    },
    {
      "@value": "58470",
      "#text": "Teixeira"
    },
    {
      "@value": "58471",
      "#text": "Telêmaco Borba"
    },
    {
      "@value": "58472",
      "#text": "Teodoro Sampaio"
    },
    {
      "@value": "58473",
      "#text": "Teófilo Otoni"
    },
    {
      "@value": "58474",
      "#text": "Terenos"
    },
    {
      "@value": "58475",
      "#text": "Teresina"
    },
    {
      "@value": "31160",
      "#text": "Teresina Aeroporto"
    },
    {
      "@value": "58476",
      "#text": "Teresópolis"
    },
    {
      "@value": "58477",
      "#text": "Terra Boa"
    },
    {
      "@value": "58478",
      "#text": "Terra Nova"
    },
    {
      "@value": "58479",
      "#text": "Terra Rica"
    },
    {
      "@value": "58480",
      "#text": "Terra Roxa"
    },
    {
      "@value": "58481",
      "#text": "Terra Santa"
    },
    {
      "@value": "58482",
      "#text": "Teutônia"
    },
    {
      "@value": "58483",
      "#text": "Tianguá"
    },
    {
      "@value": "58484",
      "#text": "Tibagi"
    },
    {
      "@value": "58485",
      "#text": "Tietê"
    },
    {
      "@value": "58486",
      "#text": "Tijucas"
    },
    {
      "@value": "58487",
      "#text": "Timbaúba"
    },
    {
      "@value": "58488",
      "#text": "Timbiras"
    },
    {
      "@value": "58489",
      "#text": "Timbó"
    },
    {
      "@value": "58490",
      "#text": "Timon"
    },
    {
      "@value": "58491",
      "#text": "Timóteo"
    },
    {
      "@value": "58492",
      "#text": "Tiradentes"
    },
    {
      "@value": "33359",
      "#text": "Tirios"
    },
    {
      "@value": "58493",
      "#text": "Tobias Barreto"
    },
    {
      "@value": "58494",
      "#text": "Tocantins"
    },
    {
      "@value": "58495",
      "#text": "Toledo"
    },
    {
      "@value": "58496",
      "#text": "Tomar do Geru"
    },
    {
      "@value": "58497",
      "#text": "Tomé Açu"
    },
    {
      "@value": "58498",
      "#text": "Tonantins"
    },
    {
      "@value": "58499",
      "#text": "Toritama"
    },
    {
      "@value": "58500",
      "#text": "Torres"
    },
    {
      "@value": "58501",
      "#text": "Touros"
    },
    {
      "@value": "58502",
      "#text": "Traipu"
    },
    {
      "@value": "58503",
      "#text": "Trairi"
    },
    {
      "@value": "58504",
      "#text": "Tramandaí"
    },
    {
      "@value": "58505",
      "#text": "Tremedal"
    },
    {
      "@value": "58506",
      "#text": "Tremembé"
    },
    {
      "@value": "58507",
      "#text": "Três Barras"
    },
    {
      "@value": "58508",
      "#text": "Três Corações"
    },
    {
      "@value": "58509",
      "#text": "Três Coroas"
    },
    {
      "@value": "58510",
      "#text": "Três de Maio"
    },
    {
      "@value": "58511",
      "#text": "Três Lagoas"
    },
    {
      "@value": "58512",
      "#text": "Três Passos"
    },
    {
      "@value": "58513",
      "#text": "Três Pontas"
    },
    {
      "@value": "58514",
      "#text": "Três Rios"
    },
    {
      "@value": "58515",
      "#text": "Trindade"
    },
    {
      "@value": "58516",
      "#text": "Triunfo"
    },
    {
      "@value": "58517",
      "#text": "Tubarão"
    },
    {
      "@value": "58518",
      "#text": "Tucano"
    },
    {
      "@value": "58519",
      "#text": "Tucumã"
    },
    {
      "@value": "58520",
      "#text": "Tucuruí"
    },
    {
      "@value": "58521",
      "#text": "Tuntum"
    },
    {
      "@value": "58522",
      "#text": "Tupã"
    },
    {
      "@value": "58523",
      "#text": "Tupaciguara"
    },
    {
      "@value": "58524",
      "#text": "Tupanatinga"
    },
    {
      "@value": "58525",
      "#text": "Tupanciretã"
    },
    {
      "@value": "58526",
      "#text": "Tupi Paulista"
    },
    {
      "@value": "58527",
      "#text": "Turiaçu"
    },
    {
      "@value": "58528",
      "#text": "Turmalina"
    },
    {
      "@value": "58529",
      "#text": "Tutóia"
    },
    {
      "@value": "58530",
      "#text": "Uarini"
    },
    {
      "@value": "58531",
      "#text": "Uauá"
    },
    {
      "@value": "58532",
      "#text": "Ubá"
    },
    {
      "@value": "58533",
      "#text": "Ubaíra"
    },
    {
      "@value": "58534",
      "#text": "Ubaitaba"
    },
    {
      "@value": "58535",
      "#text": "Ubajara"
    },
    {
      "@value": "58536",
      "#text": "Ubatã"
    },
    {
      "@value": "58537",
      "#text": "Ubatuba"
    },
    {
      "@value": "58538",
      "#text": "Uberaba"
    },
    {
      "@value": "58539",
      "#text": "Uberlândia"
    },
    {
      "@value": "58540",
      "#text": "Uiraúna"
    },
    {
      "@value": "58541",
      "#text": "Umarizal"
    },
    {
      "@value": "58542",
      "#text": "Umbaúba"
    },
    {
      "@value": "58543",
      "#text": "Umbuzeiro"
    },
    {
      "@value": "58544",
      "#text": "Umirim"
    },
    {
      "@value": "58545",
      "#text": "Umuarama"
    },
    {
      "@value": "58546",
      "#text": "Una"
    },
    {
      "@value": "33355",
      "#text": "Una Hotel Transa"
    },
    {
      "@value": "58547",
      "#text": "Unaí"
    },
    {
      "@value": "58548",
      "#text": "União"
    },
    {
      "@value": "58549",
      "#text": "União da Vitória"
    },
    {
      "@value": "58550",
      "#text": "União dos Palmares"
    },
    {
      "@value": "58551",
      "#text": "Upanema"
    },
    {
      "@value": "58552",
      "#text": "Urbano Santos"
    },
    {
      "@value": "58553",
      "#text": "Uruaçu"
    },
    {
      "@value": "58554",
      "#text": "Uruana"
    },
    {
      "@value": "33361",
      "#text": "Urubupunga/Casti"
    },
    {
      "@value": "58555",
      "#text": "Uruburetama"
    },
    {
      "@value": "58556",
      "#text": "Urucará"
    },
    {
      "@value": "58557",
      "#text": "Uruçuca"
    },
    {
      "@value": "58558",
      "#text": "Uruçuí"
    },
    {
      "@value": "58559",
      "#text": "Urucurituba"
    },
    {
      "@value": "58560",
      "#text": "Uruguaiana"
    },
    {
      "@value": "31167",
      "#text": "Uruguaiana Aeroporto"
    },
    {
      "@value": "58561",
      "#text": "Urupês"
    },
    {
      "@value": "58562",
      "#text": "Urussanga"
    },
    {
      "@value": "33330",
      "#text": "Usiminas/Paraiso"
    },
    {
      "@value": "58563",
      "#text": "Vacaria"
    },
    {
      "@value": "58564",
      "#text": "Valença"
    },
    {
      "@value": "58565",
      "#text": "Valença do Piauí"
    },
    {
      "@value": "58566",
      "#text": "Valente"
    },
    {
      "@value": "58567",
      "#text": "Valinhos"
    },
    {
      "@value": "58568",
      "#text": "Valparaíso"
    },
    {
      "@value": "58569",
      "#text": "Vargem Alta"
    },
    {
      "@value": "58570",
      "#text": "Vargem Grande"
    },
    {
      "@value": "58571",
      "#text": "Vargem Grande do Sul"
    },
    {
      "@value": "58572",
      "#text": "Varginha"
    },
    {
      "@value": "58573",
      "#text": "Varjota"
    },
    {
      "@value": "58574",
      "#text": "Várzea Alegre"
    },
    {
      "@value": "58575",
      "#text": "Várzea da Palma"
    },
    {
      "@value": "58576",
      "#text": "Várzea Grande"
    },
    {
      "@value": "58577",
      "#text": "Várzea Paulista"
    },
    {
      "@value": "58578",
      "#text": "Varzelândia"
    },
    {
      "@value": "58579",
      "#text": "Vassouras"
    },
    {
      "@value": "58580",
      "#text": "Vazante"
    },
    {
      "@value": "58581",
      "#text": "Venâncio Aires"
    },
    {
      "@value": "58582",
      "#text": "Vera Cruz"
    },
    {
      "@value": "58583",
      "#text": "Veranópolis"
    },
    {
      "@value": "58584",
      "#text": "Vespasiano"
    },
    {
      "@value": "58585",
      "#text": "Viamão"
    },
    {
      "@value": "58586",
      "#text": "Viana"
    },
    {
      "@value": "58587",
      "#text": "Vianópolis"
    },
    {
      "@value": "58588",
      "#text": "Vicência"
    },
    {
      "@value": "58589",
      "#text": "Viçosa"
    },
    {
      "@value": "58590",
      "#text": "Viçosa do Ceará"
    },
    {
      "@value": "58591",
      "#text": "Videira"
    },
    {
      "@value": "58592",
      "#text": "Vigia"
    },
    {
      "@value": "58593",
      "#text": "Vila Velha"
    },
    {
      "@value": "58594",
      "#text": "Vilhena"
    },
    {
      "@value": "31170",
      "#text": "Vilhena Aeroporto"
    },
    {
      "@value": "58595",
      "#text": "Vinhedo"
    },
    {
      "@value": "58596",
      "#text": "Viradouro"
    },
    {
      "@value": "58597",
      "#text": "Visconde do Rio Branco"
    },
    {
      "@value": "58598",
      "#text": "Viseu"
    },
    {
      "@value": "58599",
      "#text": "Vitória"
    },
    {
      "@value": "31171",
      "#text": "Vitoria Aeroporto"
    },
    {
      "@value": "58600",
      "#text": "Vitória da Conquista"
    },
    {
      "@value": "58601",
      "#text": "Vitória de Santo Antão"
    },
    {
      "@value": "58602",
      "#text": "Vitória do Mearim"
    },
    {
      "@value": "58603",
      "#text": "Vitorino Freire"
    },
    {
      "@value": "58604",
      "#text": "Volta Redonda"
    },
    {
      "@value": "58605",
      "#text": "Votorantim"
    },
    {
      "@value": "58606",
      "#text": "Votuporanga"
    },
    {
      "@value": "58607",
      "#text": "Wenceslau Braz"
    },
    {
      "@value": "58608",
      "#text": "Xanxerê"
    },
    {
      "@value": "58609",
      "#text": "Xapuri"
    },
    {
      "@value": "31172",
      "#text": "Xavantina"
    },
    {
      "@value": "58610",
      "#text": "Xique Xique"
    }
  ]
}


